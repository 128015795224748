import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import UsersList from '../pages/List/index';
import UsersForm from '../pages/Form';

const UsersRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={UsersList} />
      <Route path={`${path}/form`} exact component={UsersForm} />
      <Route path="*">
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default UsersRoute;
