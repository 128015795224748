import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import AccessGroupsList from '../pages/List/index';
import AccessGroupsForm from '../pages/Form';

const AccessGroupsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={AccessGroupsList} />
      <Route path={`${path}/form`} exact component={AccessGroupsForm} />
      <Route path="*">
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default AccessGroupsRoute;
