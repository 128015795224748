export default {
  title: 'light',

  colors: {
    primary: '#3D7FD9',
    secondary: '#EAEAEA',
    secondaryHover: '#d1d1d1',
    third: '#2C2C2C',
    primaryText: '#151515',
    secondaryText: '#f1f1f1',
    refresh: '#fcba03',
    red: '#c53030',
    passed: 'green',
  },
};
