import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class StoreApp {
  constructor(props = { withEntityName: true }) {
    this.apiApp = apiApp;
    this.withEntityName = props.withEntityName;
  }

  async store({ entityName = '', dataObj = {}, parser = null }) {
    try {
      const response = await this.apiApp.post(
        `/${plural(entityName).replace('_', '-')}`,
        dataObj
      );

      if (!this.withEntityName && parser) {
        return parser([{ ...response.data }])[0];
      }

      if (parser) {
        return parser([{ ...response.data[entityName] }])[0];
      }

      return response.data;
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao criar um ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'BaseRepositoriesAppStore',
      });

      throw errors;
    }
  }
}

export default StoreApp;
