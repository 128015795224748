import AppRepository from '~/shared/modules/repositories/app/destroy';

class UserCompanyDestroyService {
  constructor() {
    this.AppRepository = new AppRepository();
  }

  async execute({ entityName = '', dataObj = {}, logged_branch = {} }) {
    try {
      const unLinkCompany = dataObj?.user_companies?.find(
        userCompany => userCompany.company_key === logged_branch.company_key
      );

      await this.AppRepository.destroy({
        entityName,
        dataObj: unLinkCompany,
      });

      delete dataObj.user_companies;

      return { ...dataObj, user_company: unLinkCompany };
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao desvincular usuário a empresa`,
        local: `UserCompanyDestroyServiceExecute`,
      };
      throw errors;
    }
  }
}

export default UserCompanyDestroyService;
