import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import AppRepository from '../repositories/app/update';

class UpdateBaseService {
  constructor() {
    this.appRepository = new AppRepository();
  }

  async execute({ entityName = '', dataObj = {}, parser }) {
    try {
      const baseData = await this.appRepository.update({
        entityName,
        dataObj,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao atualizar ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: `StoreBaseServiceExecute`,
      };

      throw errors;
    }
  }
}

export default UpdateBaseService;
