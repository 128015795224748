import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableListToolbar, { newItemPropType } from './Toolbar';

import TableListHead, {
  cellsPropType,
  selectPropType,
  actionsPropType,
} from './Head';
import { reprocessModalPropType, reprocessFieldsPropType } from './Row';
import TableListBody from './Body';
import { debounceEvent } from '~/shared/providers/utils/debounce';

const useTableStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minWidth: 700,
    display: 'flex',
    height: '99%',
    position: 'relative',
  },
  loadingWrapper: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    top: 70,
    bottom: 70,
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    height: 'calc(100vh - 120px)',
  },
  tableContainerDash: {
    height: 'calc(50vh - 80px)',
  },
  table: {
    minWidth: 100,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableList({
  title,
  newItem,
  headCells,
  otherCells,
  observationCells,
  customRowHeadCells,
  data,
  loadData,
  loading,
  actions,
  reprocessModal,
  reprocessFields,
  reprocessSubmit,
  select,
  canEdit,
  onPageChange,
}) {
  const classes = useTableStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const searchRef = useRef({ value: '' });

  const rows = useMemo(
    () =>
      data.map(item => {
        const itemParsed = { ...item };

        if (otherCells)
          otherCells.forEach(otherCell => {
            itemParsed[otherCell.id] = item[otherCell.id];
          });

        if (observationCells)
          observationCells.forEach(observationCell => {
            itemParsed[observationCell.id] = item[observationCell.id];
          });

        return itemParsed;
      }),
    [otherCells, observationCells, data]
  );

  const handleChangeSearchText = text => {
    if (searchRef.current) searchRef.current.value = text;
    debounceEvent(() => loadData(text.trim(), orderBy, order), 500)();
  };

  const handleRequestSort = (event, property) => {
    const orderAux = orderBy === property && order === 'asc' ? 'desc' : 'asc';

    setOrder(orderAux);
    setOrderBy(property);

    loadData(searchRef.current && searchRef.current.value, property, orderAux);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      onPageChange();
    }, 200);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
      )}
      <Paper className={classes.paper}>
        <TableListToolbar
          title={title}
          newItem={newItem}
          searchRef={searchRef}
          handleChangeSearchText={handleChangeSearchText}
          canEdit={canEdit}
        />
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader size="small" className={classes.table}>
            <TableListHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              actions={actions}
              select={select}
            />
            <TableListBody
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              headCells={headCells}
              otherCells={otherCells}
              observationCells={observationCells}
              data={data}
              reprocessModal={reprocessModal}
              reprocessFields={reprocessFields}
              reprocessSubmit={reprocessSubmit}
              actions={actions}
              select={select}
              customRowHeadCells={customRowHeadCells}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default TableList;
export { WrapperContainer } from './styles';

TableList.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  newItem: newItemPropType,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  reprocessModal: reprocessModalPropType,
  reprocessFields: reprocessFieldsPropType,
  reprocessSubmit: PropTypes.func,
  observationCells: cellsPropType,
  customRowHeadCells: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loadData: PropTypes.func,
  loading: PropTypes.bool,
  actions: actionsPropType,
  select: selectPropType,
  canEdit: PropTypes.bool,
  onPageChange: PropTypes.func,
};

TableList.defaultProps = {
  newItem: null,
  otherCells: null,
  observationCells: null,
  customRowHeadCells: null,
  data: [],
  loadData: () => {},
  loading: false,
  select: null,
  actions: null,
  reprocessModal: null,
  reprocessFields: null,
  reprocessSubmit: null,
  canEdit: true,
  onPageChange: () => {},
};
