import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useBase } from '~/shared/modules/hooks/useBase';
import { useSession } from '~/modules/sessions/hooks/useSession';
import { Form, Chip } from '~/shared/components/Form';
import { useUser } from '../../hooks/useUser';
import { useUserCompany } from '../../hooks/useUserCompany';

import storeOrUpdateValidatorSchema from '../../validators/userStoreOrUpdate';
import linkUserToCompanyValidatorSchema from '../../validators/userCompanyStore';
import { parseDataToStoreOrUpdate, parseToView } from '../../sanitizers/user';

import {
  Container,
  TitleBox,
  Typography,
  TypographyDescription,
  Divider,
} from './styles';
import { ViewUser } from './ViewUser';
import { RegisterUser } from './RegisterUser';

const Formulary = () => {
  const location = useLocation();
  const formRef = useRef(null);

  const { logged_branch, current_accesses, application_key } = useSession();
  const { index, registers } = useBase();
  const { store: userStore, update, user } = useUser();
  const { store: userCompanyStore } = useUserCompany();

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isPassword, setIsPassword] = useState(false);

  const loadCompanys = useCallback(
    // TO-DO: [x] fazer o indexSelectorCompany para bsucar um array de companies para usar no selctor.
    // TO-DO: [2] deve retornar em tela o array de companies e setar um useState.
    // TO-DO: [3] falta passa no back-end como query.params o for_selector.
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({
        entityName: 'company',
        search,
        order_by,
        order,
        parser: parseToView,
      });
    },
    [index]
  );

  const handleLinkUserToCompany = useCallback(
    async data => {
      let auxData = {
        ...data,
      };

      if (
        !current_accesses.roles[`${application_key}.admin`] ||
        !data.company_key ||
        data.company_key === ''
      ) {
        auxData = {
          ...auxData,
          company_key: logged_branch.company_key,
        };
      }

      if (
        auxData.company_key &&
        current_accesses.roles[`${application_key}.admin`]
      ) {
        const findedCompany = registers.companies.find(
          company => company.company_key === auxData.company_key
        );

        const customerGroupInputRef =
          formRef.current.getFieldRef('company_key');
        customerGroupInputRef.handleChange?.(findedCompany);
      }

      const userData = await userCompanyStore({
        dataObj: auxData,
      });

      if (userData.errorMessages) {
        formRef.current.setErrors(userData.errorMessages);

        return;
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);

      setIsValidEmail(userData.isValidEmail);
    },
    [
      userCompanyStore,
      registers.companies,
      current_accesses,
      application_key,
      logged_branch,
    ]
  );

  const handleSubmit = useCallback(
    async data => {
      let parsedData = data;

      if (
        !current_accesses.roles[`${application_key}.admin`] ||
        !data.company_key ||
        data.company_key === ''
      ) {
        parsedData = {
          ...data,
          company_key: logged_branch.company_key,
        };
      }

      if (!isPassword) {
        delete parsedData.password;
        delete parsedData.password_confirmation;
      }

      if (user?.uuid)
        await update({ dataObj: parsedData, parser: parseToView });
      else
        await userStore({
          dataObj: { ...parsedData, email: parsedData.email.toLowerCase() },
          parser: parseToView,
        });
    },
    [
      logged_branch,
      user,
      userStore,
      update,
      isPassword,
      application_key,
      current_accesses,
    ]
  );

  useEffect(() => {
    if (user?.uuid) setIsValidEmail(true);
  }, [user]);

  useEffect(() => {
    if (current_accesses.roles[`${application_key}.admin`]) {
      if (!registers?.companies) loadCompanys();
    }
  }, [loadCompanys, registers, current_accesses, application_key]);

  return (
    <Container>
      <Form
        formRef={formRef}
        onSubmit={isValidEmail ? handleSubmit : handleLinkUserToCompany}
        dataParser={parseDataToStoreOrUpdate}
        validatorSchema={
          isValidEmail
            ? storeOrUpdateValidatorSchema
            : linkUserToCompanyValidatorSchema
        }
        initialData={{
          email: formRef?.current?.getFieldRef('email').value,
          company_key: formRef?.current?.getFieldRef('company_key').fullValue,
        }}
      >
        <TitleBox>
          <Typography>
            Formulário de Usuários
            {user?.uuid && <Chip name="uuid" label="uuid" disabled />}
          </Typography>

          <TypographyDescription>
            Preencha os campos abaixo:
          </TypographyDescription>
        </TitleBox>
        <TitleBox>
          <Divider />
        </TitleBox>
        {location?.state?.newUser ? (
          <RegisterUser
            formRef={formRef}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isValidEmail={isValidEmail}
          />
        ) : (
          <ViewUser
            formRef={formRef}
            isPassword={isPassword}
            setIsPassword={setIsPassword}
          />
        )}
      </Form>
    </Container>
  );
};

export default Formulary;
