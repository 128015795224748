import { plural } from 'pluralize';
import AppRepository from '../repositories/app/list';

import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class ListBaseService {
  constructor() {
    this.appRepository = new AppRepository();
  }

  async execute({
    entityName = '',
    search = '',
    order_by = '',
    order = '',
    page = 1,
    parser,
  }) {
    try {
      const baseData = await this.appRepository.list({
        entityName,
        order,
        order_by,
        page,
        search,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao listar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: `ListBaseServiceExecute`,
      };

      throw errors;
    }
  }
}

export default ListBaseService;
