import { createContext, useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import UpdateService from '~/shared/modules/services/update';

import { useErrorHandler } from '~/shared/errors/hook';

const INITIAL_STATE = {
  loading: false,
};

const ProfileContext = createContext(INITIAL_STATE);

const updateService = new UpdateService();
export function ProfileProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const location = useLocation();

  const [data, setData] = useState(INITIAL_STATE);

  const query = new URLSearchParams(location.search);
  const appUrl = query.get('url');

  const setProfileData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const updateUser = useCallback(
    async ({ dataObj }) => {
      try {
        setProfileData({ loading: true });
        await updateService.execute({
          entityName: 'users',
          dataObj,
        });

        setProfileData({ loading: false });
        toast.success('Dados alterados com sucesso!');
        window.parent.postMessage(
          { message: 'sendUpdateUserSuccess', userData: dataObj },
          appUrl
        );
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => updateUser({ dataObj }),
        });

        setProfileData({ loading: false });

        throw error;
      }
    },
    [setProfileData, setErrorHandlerData] // eslint-disable-line
  );

  return (
    <ProfileContext.Provider
      value={{
        ...data,
        updateUser,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfile() {
  const context = useContext(ProfileContext);

  if (!context)
    throw new Error('useProfile must be used within an ProfileProvider');

  return context;
}

ProfileProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
