import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import ProfilePage from '../pages/Profile';

const ProfileRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ProfilePage} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default ProfileRoute;
