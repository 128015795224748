import { plural } from 'pluralize';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import AppRepository from '../repositories/app/indexSelector';

class IndexSelectorBaseService {
  constructor() {
    this.appRepository = new AppRepository();
  }

  async execute({ entityName = '', search = '', parser }) {
    try {
      const baseData = await this.appRepository.indexSelector({
        entityName,
        search,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao buscar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: `IndexSelectorBaseServiceExecute`,
      };

      throw errors;
    }
  }
}

export default IndexSelectorBaseService;
