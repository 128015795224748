export const getQueryParams = getQuery => params => {
  return getQuery.getAll(params);
};

export const setTokenStorage = itemsToStorage => {
  const { ...rest } = itemsToStorage;
  return localStorage.setItem(
    'CODEAUTH/externalApp:token',
    JSON.stringify({ ...rest })
  );
};

export const removeTokenStorage = () => {
  localStorage.removeItem('CODEAUTH/externalApp:token');
};

export const sendPostMessage = message => origin => {
  return window.parent.postMessage(message, origin);
};
