import styled from 'styled-components';

import ContainerStyled from '@material-ui/core/Container';
import GridStyled from '@material-ui/core/Grid';
import PaperStyled from '@material-ui/core/Paper';
import BoxStyled from '@material-ui/core/Box';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';
import LinkStyled from '@material-ui/core/Link';

export const Container = styled(ContainerStyled).attrs({
  component: 'main',
  maxWidth: 'xl',
  direction: 'column',
})`
  margin-top: 4em;
  img {
    width: 150px;
    padding: 1em;
    object-fit: contain;
  }
  height: calc(100vh - 200px);
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: left;
`;

export const LoginContainer = styled(PaperStyled).attrs({})`
  padding: 1em;
  &.MuiPaper-root {
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const LoginItems = styled(GridStyled).attrs({
  alignItems: 'center',
  justify: 'center',
  direction: 'column',
  container: true,
  item: true,
})``;

export const ErrorBox = styled(BoxStyled).attrs({})`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  gap: 1em;
`;

export const Typography = styled(TypographyStyled).attrs(props => ({
  component: 'h1',
  variant: props.token ? 'body1' : 'h5',
  color: 'textPrimary',
}))``;

export const Button = styled(ButtonStyled).attrs({
  variant: 'contained',
  color: 'primary',
  type: 'submit',
})``;

export const ForgotPasswordContainer = styled(GridStyled).attrs({
  container: true,
})`
  margin-top: 1em;
`;

export const ForgotPasswordContent = styled(GridStyled).attrs({
  xs: 6,
  item: true,
})``;

export const Link = styled(LinkStyled).attrs({
  variant: 'body2',
  color: 'textPrimary',
})``;
