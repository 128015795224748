/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Lock } from '@material-ui/icons';
import { useSession } from '../../hooks/useSession';
import validatorSchema from '../../validators/RecoverPassword';
import { formDataParseRecover } from '../../repositories/app/parse';

import { useTheme } from '~/shared/providers/hooks/theme/useTheme';
import {
  LoginItems,
  Avatar,
  Typography,
  Button,
  ForgotPasswordContainer,
  ForgotPasswordContent,
  Link,
} from './styles';

import { Form, Loading, TextInput } from '~/shared/components/Form';

function ForgotPasswordPage({ setIndex }) {
  const formRef = useRef(null);
  const location = useLocation();

  const { setTheme } = useTheme();

  const { forgotPassword, sessionLoading } = useSession();
  const query = new URLSearchParams(location.search);

  const sessionAppKey = query.get('application_key');
  const theme = query.get('theme');
  const appUrl = query.get('url');

  useEffect(() => {
    setTheme(theme);
  }, [sessionAppKey, setTheme, theme]);

  const handleSubmit = async ({
    email,
    application_key = sessionAppKey,
    externalApplicationUrl = appUrl,
  }) => {
    try {
      if (!externalApplicationUrl) {
        await forgotPassword({ email, application_key });
      } else {
        await forgotPassword({
          email,
          application_key,
          externalApplicationUrl,
        });
      }
    } catch (err) {
      if (err.code === 'E_VALIDATION_FAILED' && err.validations.email) {
        formRef.current?.setErrors(err.validations);
      }
      if (err.code === 'E_VALIDATION_FAILED') {
        formRef.current?.setErrors(err.validations);
      }
    }
  };

  useEffect(() => {
    window.parent.postMessage('codeAuthLoaded', appUrl);
  }, [appUrl]);

  return (
    <LoginItems>
      <Avatar>
        <Lock />
      </Avatar>
      <Typography>Recuperar Senha</Typography>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        externalApplicationUrl={appUrl}
        validatorSchema={validatorSchema}
        dataParser={formDataParseRecover}
      >
        <TextInput name="email" label="E-mail" />
        <TextInput name="email_confirmation" label="Confirmar email" />
        <Button disabled={sessionLoading} type="submit">
          {sessionLoading && <Loading />}
          Enviar E-mail
        </Button>
      </Form>
      {!location.pathname.includes('/forgot-password') && (
        <ForgotPasswordContainer>
          <ForgotPasswordContent>
            <Link onClick={() => setIndex(0)}>
              <p>Voltar para a tela de Login</p>
            </Link>
          </ForgotPasswordContent>
        </ForgotPasswordContainer>
      )}
    </LoginItems>
  );
}

export default ForgotPasswordPage;

ForgotPasswordPage.propTypes = {
  setIndex: PropTypes.func,
};

ForgotPasswordPage.defaultProps = {
  setIndex: null,
};
