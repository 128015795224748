import AppRepository from '~/shared/modules/repositories/app/store';

class UserCompanyStoreService {
  constructor() {
    this.AppRepository = new AppRepository();
  }

  async execute({ entityName = '', dataObj = {} }) {
    try {
      const userData = await this.AppRepository.store({
        entityName,
        dataObj,
      });

      return userData;
    } catch (error) {
      if (error.code === 'E_VALIDATION_FAILED') {
        let validations = error?.validations;
        if (validations?.email?.validation === 'exists') {
          validations = {
            ...validations.email,
            message:
              'Este e-mail não foi encontrado. Favor prosseguir com o cadastro de usuário.',
          };
        }

        if (validations?.email?.validation === 'compoundUnique') {
          validations = {
            ...validations.email,
          };
        }

        return { validations };
      }

      const errors = {
        ...error,
        title: `Error ao vincular usuário a empresa`,
        local: `UserCompanyStoreServiceExecute`,
      };
      throw errors;
    }
  }
}

export default UserCompanyStoreService;
