import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAccessGroup } from '../../hooks/useAccessGroup';

import { WrapperContainer } from '~/shared/components/TableList/TableList.jsx';
import { TableCheckbox } from '~/shared/components/TableCheckbox';

const headCells = [
  { key: 'level', label: 'Nível' },
  { key: 'action', label: 'Descrição' },
];

export const Permissions = ({ value, setValue, canEdit }) => {
  const { permissions } = useAccessGroup();

  const accessGroupPermissionsList = useMemo(() => {
    const auxAccessGroupPermissionsList = permissions?.map(permission => ({
      ...permission,
      key: permission.permission_key.replace(/\./g, '$'),
    }));

    return auxAccessGroupPermissionsList;
  }, [permissions]);

  const handleSelectAll = useCallback(
    event => {
      const { checked } = event?.target;

      const newPermission = {};
      accessGroupPermissionsList.forEach(permission => {
        newPermission[permission.key] = checked;
      });

      setValue(newPermission);
    },
    [accessGroupPermissionsList, setValue]
  );

  const handleChange = useCallback(
    (event, key) => {
      const { checked } = event?.target;

      setValue({
        ...value,
        [key]: checked,
      });
    },
    [value, setValue]
  );

  return (
    <WrapperContainer style={{ width: '100%' }}>
      <TableCheckbox
        title=""
        data={accessGroupPermissionsList}
        filterBy="action"
        columnInfos={headCells}
        onChangeCheckboxAll={handleSelectAll}
        onChangeCheckbox={handleChange}
        checkedKey={value}
        disabled={!canEdit}
      />
    </WrapperContainer>
  );
};

Permissions.propTypes = {
  value: PropTypes.shape({}),
  setValue: PropTypes.func,
  canEdit: PropTypes.bool,
};

Permissions.defaultProps = {
  value: {},
  setValue: () => {},
  canEdit: false,
};
