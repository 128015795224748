import styled from 'styled-components';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIGrid from '@material-ui/core/Grid';
import MUIButton from '@material-ui/core/Button';
import MUIIconButton from '@material-ui/core/IconButton';
import MUIGridListTileBar from '@material-ui/core/GridListTileBar';
import FormControlStyled from '@material-ui/core/FormControl';

import Tooltip from '../Tooltip';

export const TagsContainer = styled.div`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
  height: 100%;
  min-height: 40px;

  .input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .input-tag__tags li {
    align-items: center;
    background: #1f7ecc;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
  }

  .input-tag__tags li button {
    align-items: center;
    appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    width: 15px;
  }

  .input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
  }

  input {
    border: none !important;
    width: 100%;
    flex: 1;
    height: 100%;
    background: transparent;
    font-size: 16px;
  }
`;

export const Autocomplete = styled(MUIAutocomplete)`
  width: 100%;
  border-radius: 4px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled(TextField)``;

export const SelectorLoading = styled(CircularProgress).attrs({ size: 20 })`
  position: absolute;

  top: 10px;
  right: 11px;
`;

export const CharacterLimit = styled.p`
  position: absolute;
  top: 30px;
  right: 18px;
  padding: 0 2px;

  background: ${props => props.theme.colors.primary};
`;

export const Grid = styled(MUIGrid)`
  width: 210px;

  padding: 0;

  &.MuiGrid-container {
    width: 100%;
    margin: 0;
  }
`;

export const Button = styled(MUIButton)`
  width: 300px;
`;

export const IconButton = styled(MUIIconButton)``;

export const Loading = styled(CircularProgress).attrs({ size: 20 })`
  position: absolute;
`;

export const GridListTileBar = styled(MUIGridListTileBar)``;

export const ButtonImage = styled(MUIButton).attrs({
  color: 'primary',
  disableElevation: true,
})``;

export const Error = styled(Tooltip)`
  position: absolute;
  left: 95%;
  top: 40%;
`;

export const Helper = styled(Tooltip)`
  position: absolute;
  left: 95%;
  bottom: 70%;
`;

export const FormControl = styled(FormControlStyled)`
  width: 100%;
`;
