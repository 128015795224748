import { useTheme } from '~/shared/providers/hooks/theme/useTheme';

const Theme = (pallet = { title: '', colors: {}, logo: '', company: '' }) => {
  const { theme } = useTheme();

  return {
    title: theme || pallet.title,

    colors: {
      ...pallet.colors,
    },
    ...pallet.logo,
    ...pallet.company,
  };
};

export default Theme;
