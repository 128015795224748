import styled, { css } from 'styled-components';
import ContainerStyled from '@material-ui/core/Container';
import GridStyled from '@material-ui/core/Grid';
import BoxStyled from '@material-ui/core/Box';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';
import ListMaterial from '@material-ui/core/List';
import CheckboxStyled from '@material-ui/core/Checkbox';
import TabsStyled from '@material-ui/core/Tabs';
import PaperStyled from '@material-ui/core/Paper';
import TabStyled from '@material-ui/core/Tab';

import Tooltip from '~/shared/components/Tooltip';

export { default as Divider } from '@material-ui/core/Divider';
export { default as StepLabel } from '@material-ui/core/StepLabel';
export { default as StepContent } from '@material-ui/core/StepContent';
export { default as AppBar } from '@material-ui/core/AppBar';
export { default as SwipeableViews } from 'react-swipeable-views';
export { default as ErrorIcon } from '@material-ui/icons/Error';

export const Container = styled(ContainerStyled).attrs({
  component: 'main',
  maxWidth: 'lg',
  mt: 2,
  justify: 'start',
})``;
export const TitleBox = styled(BoxStyled).attrs({
  mt: 0.4,
})``;

export const Box = styled(BoxStyled).attrs({
  mt: 0.4,
})`
  ${props =>
    props.column === 'true' &&
    css`
      display: flex;
      flex-direction: column;
    `}

  > fieldset {
    margin-bottom: 19px;
  }
`;
export const FieldGrid = styled(GridStyled).attrs(props => ({
  container: true,
  alignItems: 'center',
  justify: 'flex-start',
  direction: props.$column ? 'column' : 'row',
}))`
  gap: 20px;
`;

export const InputSpacingGrid = styled(GridStyled).attrs({
  container: true,
  item: true,
  direction: 'row',
})``;

export const Grid = styled(GridStyled).attrs({})``;

export const Typography = styled(TypographyStyled).attrs({
  component: 'h2',
  variant: 'h4',
  color: 'textPrimary',
})`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const TypographyDescription = styled(TypographyStyled).attrs({
  component: 'p',
  variant: 'overline',
  color: 'textSecondary',
})``;

export const FormularyBoxDivider = styled(BoxStyled).attrs({
  mt: 2,
})``;

export const Button = styled(ButtonStyled).attrs(props => ({
  variant: props.cancel ? 'outlined' : 'contained',
  color: 'primary',
}))`
  &.MuiButton-root {
    margin: 10px 0;
    min-width: 80px;
  }
`;

export const List = styled(ListMaterial).attrs({})`
  width: 100%;
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: auto;
`;

export const ButtonLinkCompany = styled(ButtonStyled).attrs({
  variant: 'contained',
  color: 'secondary',
})`
  margin-bottom: 25px;
`;

export const CheckboxPassword = styled(CheckboxStyled).attrs({
  color: 'primary',
})``;

export const ContentGrid = styled(GridStyled).attrs(props => ({
  container: true,
  alignItems: 'flex-start',
  direction: props.$column ? 'column' : 'row',
}))``;

export const TabsContainer = styled.div`
  display: block;
  width: 70%;
`;

export const Paper = styled(PaperStyled).attrs({})`
  margin-bottom: 4px;
  box-shadow: none;
`;

export const Tabs = styled(TabsStyled).attrs({})`
  .MuiTab-wrapper {
    flex-direction: row;
    height: 4rem;
  }
`;

export const Tab = styled(TabStyled).attrs({})`
  &.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin: 0 6px;
  }
`;

export const IconError = styled(Tooltip)``;
