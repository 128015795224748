import styled from 'styled-components';
import CheckboxStyled from '@material-ui/core/Checkbox';

export { default as Container } from '@material-ui/core/TableHead';
export { default as TableRow } from '@material-ui/core/TableRow';
export { default as TableCell } from '@material-ui/core/TableCell';

export const CheckboxAll = styled(CheckboxStyled).attrs({
  color: 'primary',
})``;
