import styled from 'styled-components';
import MenuStyled from '@material-ui/core/Menu';
import ButtonStyled from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import RemoveIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ListItemTextStyled from '@material-ui/core/ListItemText';

export const ReprocessMenu = styled(MenuStyled).attrs({})`
  ul {
    padding: 0.6em;
    max-height: 250px;
  }
`;

export const MenuText = styled(ListItemTextStyled).attrs({
  primaryTypographyProps: {
    component: 'div',
    variant: 'body2',
  },
  secondaryTypographyProps: {
    component: 'div',
  },
})``;

export const MenuAction = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const MenuButton = styled(ButtonStyled).attrs(props => ({
  variant: props.delete ? 'outlined' : 'contained',
  color: 'secondary',
}))`
  &.MuiButton-root {
    width: ${props => (props.delete ? '100px' : '150px')};
  }
`;
export const MenuHead = styled.div``;

export const ActionsHead = styled.div``;

export const WrapperContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  width: 1px;
  flex-direction: column;
`;

export const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  gap: 5px;
`;

export const Refresh = styled(RefreshIcon).attrs({
  fontSize: 'small',
})`
  color: white;
`;

export const Remove = styled(RemoveIcon).attrs({
  fontSize: 'small',
})``;

export const Done = styled(DoneIcon).attrs({
  fontSize: 'small',
})`
  color: ${props => props.theme.colors.passed};
`;
