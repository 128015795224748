/* eslint-disable no-unused-vars */
import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ListService from '~/shared/modules/services/list';
import ShowService from '~/shared/modules/services/show';
import StoreService from '~/shared/modules/services/store';
import UpdateService from '~/shared/modules/services/update';
import DestroyService from '~/shared/modules/services/destroy';

import { useErrorHandler } from '~/shared/errors/hook';

import { entityName } from '../constantVariables/user';
import { parseToView } from '../sanitizers/user';

const INITIAL_STATE = {
  users: [],
  user: {},
  listLoading: false,
  loading: false,
};

const UserContext = createContext(INITIAL_STATE);

const listService = new ListService();
const showService = new ShowService();
const storeService = new StoreService({ withEntityName: false });
const updateService = new UpdateService();
const destroyService = new DestroyService();

export function UserProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(INITIAL_STATE);

  const setUserData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      try {
        setUserData({ listLoading: true });
        const userData = await listService.execute({
          entityName,
          search,
          order_by,
          order,
          parser: parseToView,
        });

        setUserData({ users: userData, listLoading: false });

        return userData;
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => index({ search, order_by, order }),
        });
        setUserData({ listLoading: false });
        return [];
      }
    },
    [setErrorHandlerData, setUserData]
  );

  const store = useCallback(
    async ({ dataObj = {} }) => {
      try {
        setUserData({ loading: true });
        const userData = await storeService.execute({
          entityName,
          dataObj,
          parser: parseToView,
        });

        setData(oldData => ({
          ...oldData,
          users: [userData, ...oldData.users],
          loading: false,
        }));

        history.goBack();

        toast.success('Usuário cadastrado com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => store({ dataObj }),
        });

        setUserData({ loading: false });

        throw err;
      }
    },
    [setErrorHandlerData, setUserData, history]
  );

  const show = useCallback(
    async ({ dataObj = {} }) => {
      try {
        setUserData({ loading: true });
        const userData = await showService.execute({
          entityName,
          dataObj,
          parser: parseToView,
        });

        setUserData({
          user: userData,
          loading: false,
        });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => show({ dataObj }),
        });

        setUserData({ loading: false });
      }
    },
    [setErrorHandlerData, setUserData]
  );

  const update = useCallback(
    async ({ dataObj = {} }) => {
      try {
        setUserData({ loading: true });
        const userData = await updateService.execute({
          entityName,
          dataObj,
          parser: parseToView,
        });

        setData(oldData => ({
          ...oldData,
          users: [
            userData,
            ...oldData.users.filter(user => user.uuid !== dataObj.uuid),
          ],
          loading: false,
        }));

        history.goBack();

        toast.success('Usuário atualizado com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => update({ dataObj }),
        });

        throw err;
      }
    },
    [setErrorHandlerData, setUserData, history]
  );

  const destroy = useCallback(
    async ({ dataObj = {} }) => {
      try {
        setUserData({ loading: true });
        await destroyService.execute({
          entityName,
          dataObj,
        });

        setData(oldData => ({
          ...oldData,
          users: [...oldData.users.filter(user => user.uuid !== dataObj.uuid)],
          listLoading: false,
        }));
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => destroy({ dataObj }),
        });

        setUserData({ loading: true });
        throw err;
      }
    },
    [setErrorHandlerData, setUserData]
  );

  const clearState = useCallback(async ({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        user: {},
        listLoading: false,
        loading: false,
      };
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...data,
        index,
        store,
        show,
        update,
        destroy,
        clearState,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }
  return context;
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
