import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useBase } from '~/shared/modules/hooks/useBase';
import { useUser } from '../../hooks/useUser';
import { useSession } from '~/modules/sessions/hooks/useSession';

import { TextInput, ImagePicker, Selector } from '~/shared/components/Form';
import { parseFormData } from '../../sanitizers/user';
import { setFormData } from '~/shared/providers/utils/form';

import {
  Container,
  InputSpacingGrid,
  FieldGrid,
  Box,
  TypographyDescription,
  Divider,
  FormularyBoxDivider,
  Button,
  CheckboxPassword,
  StepperButtonContentGrid,
} from './styles';

export const ViewUser = ({ formRef, isPassword, setIsPassword }) => {
  const location = useLocation();
  const history = useHistory();

  const { registers } = useBase();
  const { clearState, loading, user } = useUser();
  const { current_accesses, application_key } = useSession();

  const [canEdit, setCanEdit] = useState(false);

  const handlePassword = useCallback(
    event => {
      const { checked } = event.target;

      if (!checked) {
        formRef.current.setData({
          password: '',
          password_confirmation: '',
        });
      }

      setIsPassword(checked);
    },
    [formRef, setIsPassword]
  );

  useEffect(() => {
    if (user?.uuid) {
      const auxUser = { ...user };

      if (formRef.current) {
        const imgInputRef = formRef.current.getFieldRef('image');
        imgInputRef?.handleChange(auxUser?.image);
      }
      const formData = parseFormData(auxUser);
      setFormData({ formRef, formData });

      if (location?.state && !location?.state?.showUser) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location?.state || location?.state?.newUser) {
      setCanEdit(true);
    }
  }, [location, user, formRef]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <FieldGrid>
        <InputSpacingGrid item xs={4}>
          <ImagePicker name="image" disabled={!canEdit} />
        </InputSpacingGrid>
        <InputSpacingGrid item xs={4}>
          {current_accesses.roles[`${application_key}.admin`] &&
            location?.state?.newUser && (
              <Selector
                name="company_key"
                label="Empresa"
                idColumn="company_key"
                nameColumn="name"
                options={registers?.companies || []}
                disabled={!canEdit}
              />
            )}
        </InputSpacingGrid>
      </FieldGrid>
      <FieldGrid>
        <InputSpacingGrid item xs={4}>
          <TextInput name="name" label="Nome" disabled={!canEdit} required />
        </InputSpacingGrid>
        <InputSpacingGrid item xs={4}>
          <TextInput name="email" label="E-mail" disabled={!canEdit} required />
        </InputSpacingGrid>
      </FieldGrid>
      <FormularyBoxDivider />
      <Divider />
      <Box>
        {location?.state?.updateUser && (
          <>
            <FieldGrid>
              <TypographyDescription>Alterar Senha</TypographyDescription>
              <CheckboxPassword
                color="primary"
                checked={isPassword}
                onChange={handlePassword}
                disabled={!canEdit}
              />
            </FieldGrid>

            <FieldGrid>
              <InputSpacingGrid item xs={4}>
                <TextInput
                  type="password"
                  name="password"
                  label="Nova Senha"
                  disabled={!isPassword || !canEdit}
                />
              </InputSpacingGrid>
              <InputSpacingGrid item xs={4}>
                <TextInput
                  type="password"
                  name="password_confirmation"
                  label="Confirmar Nova Senha"
                  disabled={!isPassword || !canEdit}
                />
              </InputSpacingGrid>
            </FieldGrid>
          </>
        )}
      </Box>
      <StepperButtonContentGrid>
        <Button
          cancel="cancel"
          type="button"
          onClick={() => history.goBack()}
          color="secondary"
          disabled={loading || !canEdit}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !canEdit}
        >
          Confirmar
        </Button>
      </StepperButtonContentGrid>
    </Container>
  );
};

ViewUser.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  isPassword: PropTypes.bool,
  setIsPassword: PropTypes.func,
};

ViewUser.defaultProps = {
  formRef: null,
  isPassword: false,
  setIsPassword: () => {},
};
