import styled from 'styled-components';

import GridStyled from '@material-ui/core/Grid';
import AvatarStyled from '@material-ui/core/Avatar';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';
import LinkStyled from '@material-ui/core/Link';

export const LoginItems = styled(GridStyled).attrs({
  container: true,
  item: true,
  alignItems: 'center',
  justify: 'center',
  direction: 'column',
})``;

export const Avatar = styled(AvatarStyled).attrs({})``;

export const Typography = styled(TypographyStyled).attrs({
  component: 'h1',
  variant: 'h5',
  color: 'textPrimary',
})``;

export const Button = styled(ButtonStyled).attrs({
  variant: 'contained',
  color: 'primary',
  type: 'submit',
})``;

export const ForgotPasswordContainer = styled(GridStyled).attrs({
  container: true,
})`
  margin-top: 1em;
`;

export const ForgotPasswordContent = styled(GridStyled).attrs({
  xs: 6,
  item: true,
})``;
export const Link = styled(LinkStyled).attrs({
  variant: 'body2',
  color: 'textPrimary',
})``;
