import { Switch, Route, Redirect } from 'react-router-dom';

import Content from '~/modules/sessions/pages/Content/index.jsx';
import ForgotPassword from '~/modules/sessions/pages/ForgotPassword/index.jsx';
import RecoverPassword from '~/modules/sessions/pages/RecoverPassword/index.jsx';

export const SessionsRoute = () => (
  <Switch>
    <Route exact path="/" component={Content} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route path="/recover-password" exact component={RecoverPassword} />
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);
