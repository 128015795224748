import * as Yup from 'yup';

const schema = Yup.object().shape(
  {
    name: Yup.string().required('Este campo é obrigatório'),
    description: Yup.string().required('Este campo é obrigatório'),
    permission_keys: Yup.string().when(['role_keys'], {
      is: role_keys => !role_keys,
      then: Yup.string().required(
        'Selecione uma PERMISSÃO ou PAPEL para continuar.'
      ),
    }),
    user_keys: Yup.string().required('Selecione um USUÁRIO para continua'),

    branch_keys: Yup.string().required('Selecione um FILIAL para continua'),
    role_keys: Yup.string().when(['permission_keys'], {
      is: permission_keys => !permission_keys,
      then: Yup.string().required(
        'Selecione uma PERMISSÃO ou PAPEL para continuar.'
      ),
    }),
  },
  [
    ['permission_keys', 'role_keys'],
    ['permission_keys', 'user_keys'],
    ['role_keys', 'user_keys'],
    ['role_keys', 'branch_keys'],
    ['user_keys', 'branch_keys'],
  ]
);
export default schema;
