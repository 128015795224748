import styled, { css } from 'styled-components';
import ContainerStyled from '@material-ui/core/Container';
import GridStyled from '@material-ui/core/Grid';
import BoxStyled from '@material-ui/core/Box';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';
import ListMaterial from '@material-ui/core/List';
import CheckboxStyled from '@material-ui/core/Checkbox';
import StepperStyled from '@material-ui/core/Stepper';
import StepStyled from '@material-ui/core/Step';

export { default as Divider } from '@material-ui/core/Divider';
export { default as Tabs } from '@material-ui/core/Tabs';
export { default as StepLabel } from '@material-ui/core/StepLabel';
export { default as StepContent } from '@material-ui/core/StepContent';
export { default as Paper } from '@material-ui/core/Paper';

export const Container = styled(ContainerStyled).attrs({
  component: 'main',
  maxWidth: 'lg',
  mt: 2,
  justify: 'start',
})``;
export const TitleBox = styled(BoxStyled).attrs({
  mt: 3,
})``;

export const Box = styled(BoxStyled).attrs({
  mt: 1,
})`
  ${props =>
    props.column === 'true' &&
    css`
      display: flex;
      flex-direction: column;
    `}

  > fieldset {
    margin-bottom: 19px;
  }
`;
export const FieldGrid = styled(GridStyled).attrs(props => ({
  container: true,
  alignItems: 'center',
  justify: 'flex-start',
  direction: props.$column ? 'column' : 'row',
}))`
  gap: 20px;
`;

export const InputSpacingGrid = styled(GridStyled).attrs({
  container: true,
  justify: 'center',
  item: true,
  direction: 'row',
})``;

export const Typography = styled(TypographyStyled).attrs({
  component: 'h2',
  variant: 'h4',
  color: 'textPrimary',
})`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const TypographyDescription = styled(TypographyStyled).attrs({
  component: 'p',
  variant: 'overline',
  color: 'textSecondary',
})``;

export const FormularyBoxDivider = styled(BoxStyled).attrs({
  mt: 2,
})``;

export const Button = styled(ButtonStyled).attrs(props => ({
  variant: props.cancel ? 'outlined' : 'contained',
  color: 'primary',
}))`
  &.MuiButton-root {
    margin: 10px 0;
    min-width: 80px;
  }
`;

export const List = styled(ListMaterial).attrs({})`
  width: 100%;
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: auto;
`;

export const ButtonLinkCompany = styled(ButtonStyled).attrs({
  variant: 'contained',
  color: 'secondary',
})`
  margin-bottom: 25px;
`;

export const CheckboxPassword = styled(CheckboxStyled).attrs({
  color: 'primary',
})``;

export const ContentGrid = styled(GridStyled).attrs(props => ({
  container: true,
  alignItems: 'flex-start',
  direction: props.$column ? 'column' : 'row',
}))``;

export const ContentStepper = styled.div`
  width: 100%;
`;

export const StepperButtonContentGrid = styled(GridStyled).attrs({
  container: true,
})`
  margin-bottom: 10px;
  gap: 10px;
`;

export const Stepper = styled(StepperStyled)``;
export const Step = styled(StepStyled)``;
