import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class IndexSelectorApp {
  constructor() {
    this.apiApp = apiApp;
  }

  async indexSelector({ entityName = '', search = '', parser }) {
    try {
      const response = await this.apiApp.put(
        `/${plural(entityName).replace('_', '-')}`,
        {
          params: { search, for_selector: true },
        }
      );

      return parser([{ ...response.data }])[0];
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao atualizar um ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'BaseRepositoriesAppIndexSelectorApp',
      });

      throw errors;
    }
  }
}

export default IndexSelectorApp;
