import { useCallback } from 'react';
import { useBase } from '~/shared/modules/hooks/useBase';

import { useErrorHandler } from '~/shared/errors/hook';
import { entityName } from '../constantVariables/contract';

export function useContract() {
  const {
    index: indexBase,
    store: storeBase,
    show: showBase,
    update: updateBase,
    destroy: destroyBase,
    clearState: clearStateBase,
  } = useBase();

  const { setErrorHandlerData } = useErrorHandler();

  const index = useCallback(
    async ({ search = '', order_by = '', order = '', parser }) => {
      await indexBase({ entityName, search, order_by, order, parser });
    },
    [indexBase]
  );

  const store = useCallback(
    async ({ dataObj = {}, parser }) => {
      await storeBase({ entityName, dataObj, parser });
    },
    [storeBase]
  );

  // TO-DO: Falta listar os contratos.
  // TO-DO: Falta visualizar um contrato e pegar o UUID e para listar os grupos de acessos.
  const show = useCallback(
    async ({ dataObj = {}, parser }) => {
      try {
        const contractData = await showBase({ entityName, dataObj, parser });

        return contractData;
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => show({ dataObj, parser }),
        });

        throw err;
      }
    },
    [showBase, setErrorHandlerData]
  );

  const update = useCallback(
    async ({ dataObj = {}, parser }) => {
      await updateBase({ entityName, dataObj, parser });
    },
    [updateBase]
  );

  const destroy = useCallback(
    async ({ dataObj = {}, parser }) => {
      await destroyBase({ entityName, dataObj, parser });
    },
    [destroyBase]
  );

  const clearState = useCallback(
    async ({ all = false }) => {
      await clearStateBase({ entityName, all });
    },
    [clearStateBase]
  );

  return {
    index,
    store,
    show,
    update,
    destroy,
    clearState,
  };
}
