/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Group } from '@material-ui/icons';

import signInValidator from '../../validators/FirstSignIn';

import { Form, TextInput, Loading } from '~/shared/components/Form';
import { formDataParser } from '../../repositories/app/parse';

import { useSession } from '~/modules/sessions/hooks/useSession';

import {
  LoginItems,
  Avatar,
  Typography,
  Button,
  ForgotPasswordContainer,
  ForgotPasswordContent,
  Link,
} from './styles';

function SignIn({ setIndex }) {
  const { sessionLoading, signIn } = useSession();

  const formRef = useRef(null);

  const handleSubmit = async ({ email, password }) => {
    try {
      await signIn({ email, password });
    } catch (err) {
      if (err.code === 'E_VALIDATION_FAILED') {
        formRef.current?.setErrors(err.validations);
      }
    }
  };

  return (
    <LoginItems>
      <Avatar>
        <Group />
      </Avatar>
      <Typography>Realizar Login</Typography>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        validatorSchema={signInValidator}
        dataParser={formDataParser}
      >
        <TextInput name="email" label="E-Mail" />
        <TextInput name="password" label="Senha" type="password" />
        <Button disabled={sessionLoading} type="submit">
          {sessionLoading && <Loading />}
          Logar
        </Button>
      </Form>
      <ForgotPasswordContainer>
        <ForgotPasswordContent>
          <Link onClick={() => setIndex(2)}>
            <p>Esqueceu sua senha?</p>
          </Link>
        </ForgotPasswordContent>
      </ForgotPasswordContainer>
    </LoginItems>
  );
}

export default SignIn;

SignIn.propTypes = {
  setIndex: PropTypes.func,
};

SignIn.defaultProps = {
  setIndex: null,
};
