import { useHistory } from 'react-router-dom';

import {
  Container,
  TitleBox,
  Typography,
  TypographyDescription,
  Divider,
  SettingsContent,
  Tooltip,
} from './styles';

const SettingsPage = () => {
  const history = useHistory();
  return (
    <Container>
      <>
        <TitleBox>
          <Typography>Configurações de autenticação</Typography>
          <TypographyDescription>
            Cadastro de Usuários e grupos de acesso
          </TypographyDescription>
        </TitleBox>
        <TitleBox>
          <Divider />
        </TitleBox>
        <TitleBox>
          <Tooltip title="Usuários">
            <SettingsContent onClick={() => history.push('/settings/users')}>
              Usuários
            </SettingsContent>
          </Tooltip>
        </TitleBox>
        <TitleBox>
          <Tooltip title="Grupos de acesso">
            <SettingsContent
              onClick={() => history.push('/settings/access-groups')}
            >
              Grupos de acesso
            </SettingsContent>
          </Tooltip>
        </TitleBox>
      </>
    </Container>
  );
};

export default SettingsPage;
