import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import AppRepository from '../repositories/app/destroy';

class DestroyBaseService {
  constructor() {
    this.appRepository = new AppRepository();
  }

  async execute({ entityName = '', dataObj = {} }) {
    try {
      await this.appRepository.destroy({
        entityName,
        dataObj,
      });

      return;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao remover ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: `DestroyBaseServiceExecute`,
      };

      throw errors;
    }
  }
}

export default DestroyBaseService;
