import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { cellsPropType, selectPropType, actionsPropType } from './Head';
import { RadioGroup, Form } from '~/shared/components/Form';
import Checkbox from '~/shared/components/Form/Checkbox';

import {
  ReprocessMenu,
  MenuText,
  MenuHead,
  ActionsHead,
  MenuAction,
  MenuButton,
  Refresh,
} from './styles';

import ImageContainer from '../ImageContainer';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function TableListRow({
  row,
  headCells,
  otherCells,
  observationCells,
  reprocessModal,
  reprocessFields,
  actions,
  select,
  customRowHeadCells,
  reprocessSubmit,
}) {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [origin, setOrigin] = useState('');
  const [entity, setEntity] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [reprocessConfirmed, setReprocessConfirmed] = useState(false);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  function handleChangeOrigin(newOrigin) {
    setOrigin(newOrigin);
  }

  function handleChangeEntity(newEntity) {
    setEntity(newEntity);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = action => {
    action.handleFunction(row);
    handleClose();
  };

  const handleSelectItem = () => {
    select.handleFunction(row);
  };

  const findRemoveButton = actions?.filter(
    action => action.label === 'Remover'
  );

  const headCellItem = useCallback(
    headCell => {
      const headCellDic = {
        Image: (
          <ImageContainer
            src={headCell.id.indexOf('image') >= 0 ? [row[headCell.id]] : []}
            style={{ borderRadius: 4 }}
          />
        ),
        Checkbox: (
          <Checkbox
            name={`${headCell.entity}.${row[headCell.id]}`}
            color="primary"
          />
        ),
      };

      return headCellDic[headCell.type] ?? row[headCell.id];
    },
    [row]
  );

  const renderDeleteBtn = (state, setState, onClickDelete, rowData) => {
    if (!state) {
      return (
        <MenuButton delete="true" onClick={() => setState(true)}>
          Remover
        </MenuButton>
      );
    }

    return (
      <MenuButton delete="true" onClick={() => onClickDelete(rowData)}>
        Confirmar
      </MenuButton>
    );
  };

  const renderReprocessBtn = (
    reprocessState,
    setReprocessState,
    onClickReprocess
  ) => {
    if (!reprocessState) {
      return (
        <MenuButton reprocess="true" onClick={() => setReprocessState(true)}>
          Reprocessar
        </MenuButton>
      );
    }

    return (
      <MenuButton
        reprocess="true"
        onClick={() => onClickReprocess({ entity, row: row.uuid, origin })}
      >
        <Refresh /> Confirmar
      </MenuButton>
    );
  };

  return (
    <>
      <TableRow hover className={classes.root}>
        {otherCells ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
        {customRowHeadCells
          ? customRowHeadCells({ data: row, headCells })
          : headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
              >
                <div
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {headCellItem(headCell)}
                </div>
              </TableCell>
            ))}
        {select ? (
          <TableCell align="center">
            <IconButton onClick={handleSelectItem}>
              {row.icon || select.icon}
            </IconButton>
          </TableCell>
        ) : (
          actions && (
            <TableCell align="center">
              <IconButton onClick={handleOpenMenu}>
                <MenuOpenIcon />
              </IconButton>
              {reprocessModal ? (
                <ReprocessMenu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Form>
                    <MenuHead>
                      <MenuText
                        primary={'Origem do reprocessamento.' || origin}
                        secondary={
                          <>
                            <RadioGroup
                              name="origin"
                              label=""
                              options={reprocessModal}
                              onChange={newOrigin =>
                                handleChangeOrigin(newOrigin)
                              }
                            />
                            <Divider style={{ marginTop: '.3em' }} />
                          </>
                        }
                      />
                      <MenuText
                        primary="Qual campo deseja reprocessar?"
                        secondary={
                          <RadioGroup
                            name="entity"
                            label=""
                            options={reprocessFields}
                            onChange={newEntity =>
                              handleChangeEntity(newEntity)
                            }
                          />
                        }
                      />
                    </MenuHead>
                    <ActionsHead />

                    <MenuAction>
                      {renderDeleteBtn(
                        confirmed,
                        setConfirmed,
                        handleSelectMenu,
                        findRemoveButton[0]
                      )}
                      {/* <MenuButton
                         reprocess="true"
                         onClick={() =>
                           reprocessSubmit({ entity, row: row.uuid, origin })
                         }
                       >
                         <Refresh /> Reprocessar
                       </MenuButton> */}
                      {renderReprocessBtn(
                        reprocessConfirmed,
                        setReprocessConfirmed,
                        reprocessSubmit
                      )}
                    </MenuAction>
                  </Form>
                </ReprocessMenu>
              ) : (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {actions.map(action => (
                    <MenuItem
                      key={action.label}
                      onClick={() => handleSelectMenu(action)}
                      disabled={!!action.disabled}
                    >
                      {action.icon}
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </TableCell>
          )
        )}
      </TableRow>
      {otherCells && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headCells.length + (otherCells ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Outras Informações
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {otherCells.map(otherCell => (
                        <TableCell
                          key={otherCell.id}
                          align={otherCell.numeric ? 'right' : 'left'}
                          padding={
                            otherCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          {otherCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {otherCells.map(otherCell => (
                        <TableCell
                          key={otherCell.id}
                          align={otherCell.numeric ? 'right' : 'left'}
                          padding={
                            otherCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          <div
                            style={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row[otherCell.id]}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {observationCells && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headCells.length + (observationCells ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {observationCells.map(observationCell => (
                        <TableCell
                          key={observationCell.id}
                          align={observationCell.numeric ? 'right' : 'left'}
                          padding={
                            observationCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          {observationCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {observationCells.map(observationCell => (
                        <TableCell
                          key={observationCell.id}
                          align={observationCell.numeric ? 'right' : 'left'}
                          padding={
                            observationCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          <div
                            style={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row[observationCell.id]}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default TableListRow;

export const reprocessModalPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })
);

export const reprocessFieldsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })
);

TableListRow.propTypes = {
  row: PropTypes.shape().isRequired,
  reprocessModal: reprocessModalPropType,
  reprocessFields: reprocessFieldsPropType,
  reprocessSubmit: PropTypes.func,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  customRowHeadCells: PropTypes.func,
  observationCells: cellsPropType,
  actions: actionsPropType,
  select: selectPropType,
};

TableListRow.defaultProps = {
  otherCells: null,
  customRowHeadCells: null,
  observationCells: null,
  reprocessModal: null,
  reprocessFields: null,
  reprocessSubmit: null,
  actions: null,
  select: null,
};
