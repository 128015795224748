export function getData() {
  return {
    companies: JSON.parse(localStorage.getItem('CODEAUTH/session:companies')),
    branches: JSON.parse(localStorage.getItem('CODEAUTH/session:branches')),
    signed: JSON.parse(localStorage.getItem('CODEAUTH/session:signed')),
    user: JSON.parse(localStorage.getItem('CODEAUTH/session:user')),
    token: JSON.parse(localStorage.getItem('CODEAUTH/session:token')),
    externalToken: JSON.parse(
      localStorage.getItem('CODEAUTH/externalApp:token')
    ),
    logged_branch: JSON.parse(localStorage.getItem('CODEAUTH/session:logged_branch')), // prettier-ignore
    current_accesses: JSON.parse(localStorage.getItem('CODEAUTH/session:current_accesses')), // prettier-ignore
    sectorSelected: JSON.parse(localStorage.getItem('CODEAUTH/session:sectorSelected')), // prettier-ignore
  };
}

export function getExternalAppData() {
  return {
    token: JSON.parse(localStorage.getItem('CODEAUTH/externalApp:token')),
  };
}

export function setData(newData) {
  if (newData.companies) localStorage.setItem('CODEAUTH/session:companies', JSON.stringify(newData.companies)); // prettier-ignore
  if (newData.branches) localStorage.setItem('CODEAUTH/session:branches', JSON.stringify(newData.branches)); // prettier-ignore
  if (newData.signed || newData.signed === false) localStorage.setItem('CODEAUTH/session:signed', JSON.stringify(newData.signed)); // prettier-ignore
  if (newData.user) localStorage.setItem('CODEAUTH/session:user', JSON.stringify(newData.user)); // prettier-ignore
  if (newData.token) localStorage.setItem('CODEAUTH/session:token', JSON.stringify(newData.token)); // prettier-ignore
  if (newData.logged_branch) localStorage.setItem('CODEAUTH/session:logged_branch', JSON.stringify(newData.logged_branch)); // prettier-ignore
  if (newData.current_accesses) localStorage.setItem('CODEAUTH/session:current_accesses', JSON.stringify(newData.current_accesses)); // prettier-ignore
  if (newData.sectorSelected) localStorage.setItem('CODEAUTH/session:sectorSelected', JSON.stringify(newData.sectorSelected)); // prettier-ignore
}
