export function parseToView(users = []) {
  return users.map(user => {
    if (user?.user && user?.user_company) {
      return {
        ...user.user,
        user_companies: [user?.user_company],
      };
    }
    return { ...user };
  });
}

export function parseDataToStoreOrUpdate(user = {}) {
  return {
    uuid: user.uuid,
    email: user.email,
    name: user.name,
    company_key: user?.company_key,
    req_id: '1',
    password: user.password,
    password_confirmation: user.password_confirmation,
    image: user.image,
    is_admin: user.is_admin,
    user_access_groups: user.user_access_groups,
    user_companies: user.user_companies,
  };
}

export function parseDataToDestroy(user = {}) {
  return {
    data: [{ uuid: user.uuid }],
  };
}

export function parseCheckEmail(user = {}) {
  return {
    email: user.email,
    company_key: user.company_key,
  };
}

export function parseFormData(user = {}) {
  return {
    uuid: user.uuid,
    name: user.name,
    email: user.email,
  };
}
