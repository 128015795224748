import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class UpdateApp {
  constructor() {
    this.apiApp = apiApp;
  }

  async update({ entityName = '', dataObj = {}, parser = null }) {
    try {
      const response = await this.apiApp.put(
        `/${plural(entityName).replace('_', '-')}/${dataObj.uuid}`,
        dataObj
      );

      if (parser) return parser([{ ...response.data }])[0];

      return response.data;
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao atualizar um ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'BaseRepositoriesAppUpdate',
      });

      throw errors;
    }
  }
}

export default UpdateApp;
