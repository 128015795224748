import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ThemeProvider as StyledTheme } from 'styled-components';
import {
  ThemeProvider as MaterialTheme,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import useTheme from '~/shared/styles/theme/';

import lightTheme from '~/shared/styles/theme/light';

import darkTheme from '~/shared/styles/theme/dark';

const AppTheme = ({ children }) => {
  const location = useLocation();
  const { title } = useTheme();

  const [theme, setTheme] = useState(
    title === 'light' ? lightTheme : darkTheme
  );
  const [primaryColor, setPrimaryColor] = useState(theme.colors.primary);

  const themeMaterial = createMuiTheme(
    {
      palette: {
        type: theme.title,
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: theme.colors.secondary,
        },
        third: {
          main: theme.colors.third,
        },
        red: {
          main: theme.colors.red,
        },
      },
    },
    ptBR
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryPrimaryColor = query.get('primary_color');
    const queryTheme = query.get('theme');

    if (queryPrimaryColor) setPrimaryColor(`#${queryPrimaryColor}`);

    if (queryTheme) {
      const parsedTheme = queryTheme === 'light' ? lightTheme : darkTheme;
      parsedTheme.colors.primary = queryPrimaryColor
        ? `#${queryPrimaryColor}`
        : parsedTheme.colors.primary;
      setTheme(parsedTheme);
    }
  }, [location.search]);

  return (
    <StyledTheme theme={theme}>
      <MaterialTheme theme={themeMaterial}>{children}</MaterialTheme>
    </StyledTheme>
  );
};

export default AppTheme;

AppTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
