import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Message, Popper } from './styles';

export default function Tooltip({ type, title, Icon, ...rest }) {
  const errorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Container type={type} {...rest}>
      <Icon
        ref={errorRef}
        aria-describedby={id}
        type="button"
        onMouseEnter={handleClick}
        onMouseLeave={() => setAnchorEl(null)}
      />
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Message type={type}>{title}</Message>
      </Popper>
    </Container>
  );
}

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  Icon: PropTypes.shape({}).isRequired,
};

Tooltip.defaultProps = {
  type: '',
};
