import { Switch, Route } from 'react-router-dom';
import SettingsRoute from './Settings';
import ExternalAppAccess from '../pages/externalAppAccess';
import ProfileRoute from '~/modules/sessions/routes/profile';
import ForgotPassword from '~/modules/sessions/pages/ForgotPassword/index.jsx';
import RecoverPassword from '~/modules/sessions/pages/RecoverPassword/index.jsx';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={() => <h1>Dashboard</h1>} />
    <Route path="/settings" component={SettingsRoute} />
    <Route path="/profile" component={ProfileRoute} />
    <ForgotPassword path="/forgot-password" />
    <RecoverPassword path="/recover-password" />
    <ExternalAppAccess path="/login" />
  </Switch>
);

export default Routes;
