import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class DestroyApp {
  constructor() {
    this.apiApp = apiApp;
  }

  async destroy({ entityName = '', dataObj = {} }) {
    try {
      await this.apiApp.delete(
        `/${plural(entityName).replace('_', '-')}/${dataObj.uuid}`
      );

      return {};
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao remover um ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: `BaseRepositoriesAppDestroy`,
      });

      throw errors;
    }
  }
}

export default DestroyApp;
