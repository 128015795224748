export function parseToView(acessGroups = []) {
  return acessGroups.map(acessGroup => ({
    ...acessGroup,
  }));
}

export function parseDataToStoreOrUpdate(accessGroup = {}) {
  return {
    ...accessGroup,
  };
}

export function parseDataToDestroy(accessGroup = {}) {
  return {
    data: [{ uuid: accessGroup.uuid }],
  };
}

export function parseFormData(accessGroup = {}) {
  return {
    uuid: accessGroup.uuid,
    name: accessGroup.name,
    description: accessGroup.description,
  };
}
