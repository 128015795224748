import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import apiApp from '~/config/apiApp';
import SessionService from '../services/Session';

import { FRONT_URLS } from '~/shared/constants/shared';

import { useErrorHandler } from '~/shared/errors/hook';
import * as store from '../repositories/localStorage/session';

const INITIAL_STATE = {
  email: '',
  password: '',
  signed: false,
  token: {},
  user: {},
  companies: [],
  branches: [],
  accesses: [],
  contract: {},
  logged_branch: {},
  sessionPageIndex: 0,
  sessionLoading: false,
  current_accesses: {},
  application_key: '',
};

const sessionService = new SessionService();

const SessionContext = createContext(INITIAL_STATE);
export function SessionProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(() => {
    const sessionData = store.getData();

    if (sessionData?.token?.token && sessionData?.signed) {
      apiApp.defaults.headers.authorization = `Bearer ${sessionData.token.token}`;
      apiApp.defaults.headers[
        'Refresh-Token'
      ] = `${sessionData.token.refreshToken}`;
      return {
        ...INITIAL_STATE,
        application_key: process.env.REACT_APP_APPLICATION_KEY,
        ...sessionData,
      };
    }
    store.setData(INITIAL_STATE);

    return { ...INITIAL_STATE };
  });

  const setSessionData = useCallback((newData = INITIAL_STATE) => {
    store.setData(newData);
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const signOut = useCallback(() => {
    store.setData(INITIAL_STATE);

    apiApp.defaults.headers.authorization = null;
    apiApp.defaults.headers['Refresh-Token'] = null;

    setSessionData(INITIAL_STATE);
  }, [setSessionData]);

  const handleIframe = useCallback(
    async event => {
      if (FRONT_URLS.includes(event.origin) && event.data === 'signOut') {
        await signOut();
      }
    },
    [signOut]
  );

  useEffect(() => {
    window.addEventListener('message', handleIframe);
    return () => window.removeEventListener('message', handleIframe);
  }, [handleIframe]);

  const signIn = useCallback(
    async ({ email = '', password = '', branch_key = '', force = false }) => {
      setSessionData({
        email,
        password,
        sessionLoading: true,
        application_key: process.env.REACT_APP_APPLICATION_KEY,
      });

      try {
        const sessionData = await sessionService.store({
          email,
          password,
          branch_key,
          force,
        });

        if (sessionData?.token) {
          sessionData.signed = true;
        } else if (!data.sessionPageIndex) {
          sessionData.sessionPageIndex = data.sessionPageIndex + 1;
        } else {
          sessionData.email = '';
          sessionData.password = '';
        }

        setSessionData({
          ...sessionData,
        });
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => signIn({ email, password, branch_key, force }),
        });

        throw error;
      } finally {
        setSessionData({ sessionLoading: false });
      }
    },
    [setSessionData, setErrorHandlerData, data.sessionPageIndex]
  );

  const changeBranch = useCallback(
    async ({ branch_key = '', force = false }) => {
      setSessionData({ sessionLoading: true });
      try {
        const sessionData = await sessionService.store({
          branch_key,
          force,
        });

        setSessionData({ ...sessionData, sessionLoading: false });
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => changeBranch({ branch_key, force }),
        });

        setSessionData({ sessionLoading: false });
      }
    },
    [setSessionData, setErrorHandlerData]
  );

  const refreshToken = useCallback(async () => {
    setSessionData({ authLoading: true });

    const sessionData = await sessionService.refreshToken();

    if (sessionData.authErrors) {
      const appOrigin = localStorage.getItem('@appOrigin');
      window?.parent?.postMessage('singnOutCodeAuth', appOrigin);
      signOut();
    } else {
      setSessionData({ ...sessionData, authLoading: false });
    }
  }, [setSessionData, signOut]);

  const getRecoverPasswordToken = useCallback(
    async ({ password_token, externalApplicationUrl }) => {
      try {
        const sessionData = await sessionService.getRecoverPasswordToken({
          password_token,
        });
        return { ...sessionData };
      } catch (error) {
        if (!externalApplicationUrl) {
          setErrorHandlerData({
            ...error,
            resolveFunction: () => getRecoverPasswordToken({ password_token }),
          });
        } else {
          window.parent.postMessage(
            'sendTokenValidateError',
            externalApplicationUrl
          );
        }
        throw error;
      }
    },
    [setErrorHandlerData]
  );

  const forgotPassword = useCallback(
    async ({ email, application_key, externalApplicationUrl }) => {
      setSessionData({ sessionLoading: true });
      try {
        const sessionData = await sessionService.forgotPassword({
          email,
          application_key,
        });

        if (externalApplicationUrl.length) {
          window.parent.postMessage(
            'sendToastValidationSuccess',
            externalApplicationUrl
          );
        }

        setSessionData({ ...sessionData, sessionLoading: false });
      } catch (error) {
        if (!externalApplicationUrl) {
          setErrorHandlerData({
            ...error,
            resolveFunction: () => forgotPassword({ email, application_key }),
          });
        } else if (error?.validations?.email) {
          window.parent.postMessage(
            'sendToastNoEmailFound',
            externalApplicationUrl
          );
        } else {
          window.parent.postMessage(
            'errorForgotPassword',
            externalApplicationUrl
          );
        }

        throw error;
      } finally {
        setSessionData({ sessionLoading: false });
      }
    },
    [setErrorHandlerData, setSessionData]
  );

  const recoverPassword = useCallback(
    async ({ password, password_confirmation, password_token }) => {
      const sessionData = await sessionService.recoverPassword({
        password,
        password_confirmation,
        password_token,
      });
      if (sessionData.sessionErrors) {
        setErrorHandlerData({
          error: {
            ...sessionData.sessionErrors,
            resolveFunction: () =>
              recoverPassword({
                password,
                password_confirmation,
                password_token,
              }),
          },
        });
      }
      setSessionData({ sessionLoading: false });
    },
    [setErrorHandlerData, setSessionData]
  );

  const getSession = useCallback(
    async ({ application_key }) => {
      try {
        const sessionData = await sessionService.get({ application_key });

        setSessionData({
          ...sessionData,
        });
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => getSession(),
        });

        throw error;
      } finally {
        setSessionData({ sessionLoading: false });
      }
    },
    [setSessionData, setErrorHandlerData]
  );

  const handleExternalLogin = useCallback(
    async ({
      token,
      refreshToken: auxRefreshToken,
      type,
      contract,
      current_accesses,
      application_key,
      logged_branch,
    }) => {
      setSessionData({ sessionLoading: true });

      try {
        setSessionData({
          token: {
            token,
            refreshToken: auxRefreshToken.replace(/\s/g, '+'),
            type,
          },
          contract,
          current_accesses,
          application_key,
          signed: true,
          logged_branch,
        });

        const sessionData = store.getData();

        if (sessionData?.token && sessionData?.signed) {
          apiApp.defaults.headers.authorization = `Bearer ${sessionData.token.token}`;
          apiApp.defaults.headers[
            'Refresh-Token'
          ] = `${sessionData.token.refreshToken}`;
          apiApp.defaults.headers['Application-Keys'] = `${application_key}`;
        }

        getSession({ application_key });
        return { ...sessionData };
      } catch (err) {
        setErrorHandlerData({
          error: {
            ...err,
          },
        });
        throw err;
      } finally {
        setSessionData({ sessionLoading: false });
      }
    },
    [setSessionData, setErrorHandlerData, getSession]
  );

  return (
    <SessionContext.Provider
      value={{
        ...data,
        signIn,
        changeBranch,
        signOut,
        refreshToken,
        forgotPassword,
        getRecoverPasswordToken,
        recoverPassword,
        handleExternalLogin,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);

  if (!context)
    throw new Error('useSession must be used within an SessionProvider');

  return context;
}

SessionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
