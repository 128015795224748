import PropTypes from 'prop-types';

import TableBody from '@material-ui/core/TableBody';
import TableListRow, {
  reprocessModalPropType,
  reprocessFieldsPropType,
} from './Row';
import { cellsPropType, selectPropType, actionsPropType } from './Head';

function TableListBody({
  rows,
  page,
  rowsPerPage,
  headCells,
  otherCells,
  observationCells,
  reprocessModal,
  reprocessFields,
  reprocessSubmit,
  data,
  actions,
  select,
  customRowHeadCells,
}) {
  return (
    <TableBody>
      {rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(row => (
          <TableListRow
            key={row.id}
            row={row}
            headCells={headCells}
            otherCells={otherCells}
            observationCells={observationCells}
            actions={actions}
            data={data}
            reprocessModal={reprocessModal}
            reprocessFields={reprocessFields}
            reprocessSubmit={reprocessSubmit}
            select={select}
            customRowHeadCells={customRowHeadCells}
          />
        ))}
    </TableBody>
  );
}

TableListBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  customRowHeadCells: PropTypes.func,
  observationCells: cellsPropType,
  actions: actionsPropType,
  reprocessModal: reprocessModalPropType,
  reprocessFields: reprocessFieldsPropType,
  reprocessSubmit: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  select: selectPropType,
};

TableListBody.defaultProps = {
  otherCells: null,
  customRowHeadCells: null,
  observationCells: null,
  rows: [],
  data: [],
  actions: null,
  reprocessModal: null,
  reprocessFields: null,
  reprocessSubmit: null,
  select: true,
};

export default TableListBody;
