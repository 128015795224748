import AppRepository from '../repositories/app/newAccessGroup';

class NewAccessGroupService {
  constructor() {
    this.appRepository = new AppRepository();
  }

  async execute({ entityName = '', dataObj = {}, parser }) {
    try {
      const AccessGroupData = await this.appRepository.newAccessGroup({
        entityName,
        dataObj,
        parser,
      });

      return AccessGroupData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao buscar dados para cria um Novo Grupo de Acesso`,
        local: `NewAccessGroupServiceExecute`,
      };

      throw errors;
    }
  }
}

export default NewAccessGroupService;
