import * as Yup from 'yup';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Este não é um email válido')
    .required('Este campo é obrigatório'),
  email_confirmation: Yup.string().oneOf(
    [Yup.ref('email'), null],
    'Email não correspondem'
  ),
});

export default schema;
