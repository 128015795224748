import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class ListApp {
  constructor() {
    this.apiApp = apiApp;
  }

  async list({
    entityName = '',
    search = '',
    order_by = '',
    order = '',
    page = 1,
    parser = () => {},
  }) {
    try {
      const response = await this.apiApp.get(
        `/${plural(entityName).replace('_', '-')}`,
        {
          params: { search, order_by, order, page },
        }
      );

      return parser(response.data);
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao listar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: `BaseRepositoriesAppIndex`,
      });

      throw errors;
    }
  }
}

export default ListApp;
