import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WarningDialog from '~/shared/components/Dialogs/WarningDialog';
import { useSession } from '~/modules/sessions/hooks/useSession';
import { useUser } from '../../hooks/useUser';
import { useUserCompany } from '../../hooks/useUserCompany';
import TableList from '~/shared/components/TableList/TableList.jsx';
import { parseToView } from '~/modules/users/sanitizers/user';

import { Container } from './styles';

const headCells = [
  { id: 'image', label: 'Imagem', type: 'Image', disablePadding: true },
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
  { id: 'email', label: 'E-mail' },
];

function List() {
  const history = useHistory();

  const { current_accesses, application_key } = useSession();
  const { index, show, destroy, listLoading, users } = useUser();
  const { destroy: userCompanyDestroy } = useUserCompany();

  const [loaded, setLoaded] = useState(false);
  const [showWarningDialog, setShowWarnigDialog] = useState(false);
  const [userData, setUserData] = useState({});

  const loadUsers = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({
        search,
        order_by,
        order,
      });
    },
    [index]
  );

  const newItem = useMemo(() => {
    if (
      current_accesses.roles[`${application_key}.admin`] ||
      current_accesses.roles[`${application_key}.manager`]
    ) {
      return {
        label: 'ADICIONAR',
        handleFunction: async () => {
          history.push('/settings/users/form', { newUser: true });
        },
      };
    }

    return null;
  }, [history, current_accesses, application_key]);

  const handleRemove = useCallback(async () => {
    await destroy({
      dataObj: { ...userData, req_id: '1' },
    });
    setShowWarnigDialog(false);
  }, [destroy, userData]);

  const actions = useMemo(() => {
    const auxActions = [];

    if (
      current_accesses.roles[`${application_key}.admin`] ||
      current_accesses.roles[`${application_key}.manager`] ||
      current_accesses.permissions[`${application_key}.users.get`]
    ) {
      auxActions.push({
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async auxUser => {
          history.push('/settings/users/form', { showUser: true });

          await show({
            dataObj: auxUser,
            parser: parseToView,
          });
        },
      });
    }

    if (current_accesses.roles[`${application_key}.admin`]) {
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async auxUser => {
          history.push('/settings/users/form', { updateUser: true });

          await show({
            dataObj: auxUser,
            parser: parseToView,
          });
        },
      });
    }

    if (current_accesses.roles[`${application_key}.admin`]) {
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: async auxUser => {
          setUserData(auxUser);
          setShowWarnigDialog(true);
        },
      });
    }

    if (
      current_accesses.roles[`${application_key}.admin`] ||
      current_accesses.roles[`${application_key}.manager`]
    ) {
      auxActions.push({
        label: 'Desvincular',
        icon: <UnfoldMoreIcon fontSize="small" />,
        handleFunction: async auxUser => {
          await userCompanyDestroy({ dataObj: auxUser });
        },
      });
    }

    return auxActions;
  }, [history, show, application_key, current_accesses, userCompanyDestroy]);

  useEffect(() => {
    if (
      current_accesses.roles[`${application_key}.admin`] ||
      current_accesses.roles[`${application_key}.manager`] ||
      current_accesses.permissions[`${application_key}.users.get`]
    ) {
      if (!loaded && !users.length) loadUsers();

      setLoaded(true);
    }
  }, [loadUsers, loaded, users, current_accesses, application_key]);

  return (
    <Container>
      <WarningDialog
        showDialog={showWarningDialog}
        setShowDialog={setShowWarnigDialog}
        description="Tem certeza que deseja remover o usuário?"
        onSuccess={handleRemove}
        loading={listLoading}
      />
      <TableList
        title=""
        newItem={newItem}
        headCells={headCells}
        data={users}
        loadData={loadUsers}
        loading={listLoading}
        actions={actions}
      />
    </Container>
  );
}

export default List;
