import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '200%',
  },
  closeButton: {
    position: 'absolute',
  },
}));

function TableListToolbar({
  title,
  newItem,
  searchRef,
  handleChangeSearchText,
  canEdit,
}) {
  const classes = useToolbarStyles();

  const handleChangeText = event => {
    handleChangeSearchText(event.target.value);
  };

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" component="div">
        {title}
        {newItem && (
          <Button
            variant="contained"
            color="primary"
            onClick={newItem.handleFunction}
            disabled={!canEdit}
            style={{ marginLeft: 10 }}
          >
            {newItem.label}
          </Button>
        )}
      </Typography>
      <div
        style={{
          width: 500,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Buscar..."
          variant="outlined"
          inputRef={searchRef}
          size="small"
          onChange={handleChangeText}
        />
        {searchRef.current && searchRef.current.value && (
          <IconButton
            size="small"
            className={classes.closeButton}
            onClick={() => handleChangeSearchText('')}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <Tooltip title="Filtrar">
        <IconButton
          onClick={() =>
            handleChangeSearchText(searchRef.current && searchRef.current.value)
          }
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

export const newItemPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  handleFunction: PropTypes.func.isRequired,
});

TableListToolbar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  newItem: newItemPropType,
  searchRef: PropTypes.shape().isRequired,
  handleChangeSearchText: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

TableListToolbar.defaultProps = {
  newItem: null,
  canEdit: true,
};

export default TableListToolbar;
