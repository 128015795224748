import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAccessGroup } from '../../hooks/useAccessGroup';

import { WrapperContainer } from '~/shared/components/TableList/TableList.jsx';
import { TableCheckbox } from '~/shared/components/TableCheckbox';

const headCells = [
  { key: 'first_name', label: 'Nome' },
  { key: 'last_name', label: 'Nome Fantasia' },
];

export const Branches = ({ value, setValue, canEdit }) => {
  const { branches } = useAccessGroup();

  const accessGroupBranchesList = useMemo(() => {
    const auxAccessGroupBranchesList = branches.map(branch => ({
      ...branch,
      key: branch.branch_key.replace(/\./g, '$'),
    }));

    return auxAccessGroupBranchesList;
  }, [branches]);

  const handleSelectAll = useCallback(
    event => {
      const { checked } = event?.target;

      const newBranch = {};
      accessGroupBranchesList.forEach(branch => {
        newBranch[branch.key] = checked;
      });

      setValue(newBranch);
    },
    [setValue, accessGroupBranchesList]
  );

  const handleChange = useCallback(
    (event, key) => {
      const { checked } = event?.target;

      setValue({
        ...value,
        [key]: checked,
      });
    },
    [value, setValue]
  );

  return (
    <WrapperContainer style={{ width: '100%' }}>
      <TableCheckbox
        title=""
        data={accessGroupBranchesList}
        filterBy="first_name"
        columnInfos={headCells}
        onChangeCheckboxAll={handleSelectAll}
        onChangeCheckbox={handleChange}
        checkedKey={value}
        disabled={!canEdit}
      />
    </WrapperContainer>
  );
};

Branches.propTypes = {
  value: PropTypes.shape({}),
  setValue: PropTypes.func,
  canEdit: PropTypes.bool,
};

Branches.defaultProps = {
  value: {},
  setValue: () => {},
  canEdit: false,
};
