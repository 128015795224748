import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import SettingsPage from '../pages/settings/index';
import UsersRoute from '~/modules/users/routes';
import AccessGroupsRoute from '~/modules/accessGroups/routes';

const SettingsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={SettingsPage} />
      <Route path={`${path}/access-groups`} component={AccessGroupsRoute} />
      <Route path={`${path}/users`} component={UsersRoute} />
      <Route path="*">
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default SettingsRoute;
