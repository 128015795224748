import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import yupValidator from '~/shared/providers/yup';

import { Container } from './styles';

function Form({
  formRef,
  validatorSchema,
  dataParser,
  onSubmit,
  onValidationError,
  externalApplicationUrl,
  children,
  customData,
  ...rest
}) {
  const handleSubmit = async formData => {
    const parseFormData = { ...formData, ...customData };
    const { errorMessages, ...parsedData } = await yupValidator(
      parseFormData,
      dataParser,
      validatorSchema
    );
    if (errorMessages) {
      formRef?.current?.setErrors(errorMessages);
      onValidationError?.(errorMessages);
      if (!externalApplicationUrl) {
        toast.warn('Ocorreu um erro de validação');
      } else {
        window.parent.postMessage(
          'sendToastValidationError',
          externalApplicationUrl
        );
      }
    } else onSubmit(parsedData);
  };

  return (
    <Container ref={formRef} onSubmit={handleSubmit} {...rest}>
      {children}
    </Container>
  );
}

export default Form;

Form.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  validatorSchema: PropTypes.shape(),
  onValidationError: PropTypes.func,
  dataParser: PropTypes.func,
  onSubmit: PropTypes.func,
  externalApplicationUrl: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  customData: PropTypes.shape({}),
};

Form.defaultProps = {
  dataParser: null,
  validatorSchema: null,
  onValidationError: null,
  externalApplicationUrl: null,
  onSubmit: null,
  formRef: null,
  customData: null,
};
