import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAccessGroup } from '../../hooks/useAccessGroup';

import { WrapperContainer } from '~/shared/components/TableList/TableList.jsx';
import { TableCheckbox } from '~/shared/components/TableCheckbox';

const headCells = [
  { key: 'name', label: 'Nome' },
  { key: 'email', label: 'E-mail' },
];

export const Users = ({ value, setValue, canEdit }) => {
  const { users } = useAccessGroup();

  const accessGroupUsersList = useMemo(() => {
    const auxAccessGroupUsersList = users.map(user => ({
      ...user,
      key: user.user_key.replace(/\./g, '$'),
    }));

    return auxAccessGroupUsersList;
  }, [users]);

  const handleSelectAll = useCallback(
    event => {
      const { checked } = event?.target;

      const newUser = {};
      accessGroupUsersList.forEach(user => {
        newUser[user.key] = checked;
      });

      setValue(newUser);
    },
    [setValue, accessGroupUsersList]
  );

  const handleChange = useCallback(
    (event, key) => {
      const { checked } = event?.target;

      setValue({
        ...value,
        [key]: checked,
      });
    },
    [setValue, value]
  );

  return (
    <WrapperContainer style={{ width: '100%' }}>
      <TableCheckbox
        title=""
        data={accessGroupUsersList}
        filterBy="name"
        columnInfos={headCells}
        onChangeCheckboxAll={handleSelectAll}
        onChangeCheckbox={handleChange}
        checkedKey={value}
        disabled={!canEdit}
      />
    </WrapperContainer>
  );
};

Users.propTypes = {
  value: PropTypes.shape({}),
  setValue: PropTypes.func,
  canEdit: PropTypes.bool,
};

Users.defaultProps = {
  value: {},
  setValue: () => {},
  canEdit: false,
};
