import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';

class NewAcessGroupApp {
  constructor() {
    this.apiApp = apiApp;
  }

  async newAccessGroup({ entityName = '', dataObj = {}, parser }) {
    try {
      const response = await this.apiApp.get(
        `/new-${entityName.replace('_', '-')}/${dataObj.uuid}`
      );

      return parser([{ ...response.data }])[0];
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao buscar dados para cria um Novo Grupo de Acesso`,
        local: 'RepositoriesAppNewAccessGroup',
      });

      throw errors;
    }
  }
}

export default NewAcessGroupApp;
