import PropTypes from 'prop-types';
import { ToAlphanumeric } from '~/shared/sanitizers/string';

import {
  Container,
  IconButton,
  AddIcon,
  Typography,
  TextField,
} from './styles';

const Header = ({ data, filterBy, addFunction, title, setFilteredData }) => {
  const handleFilterData = search => {
    const lowerText = ToAlphanumeric(search?.toLowerCase());
    const upperText = ToAlphanumeric(search?.toUpperCase());

    const newFilteredData = data.filter(
      itemData =>
        ToAlphanumeric(itemData[filterBy]).toLowerCase().includes(lowerText) ||
        ToAlphanumeric(itemData[filterBy]).toUpperCase().includes(upperText)
    );

    setFilteredData(newFilteredData);
  };

  return (
    <Container>
      {addFunction && (
        <IconButton onClick={addFunction}>
          <AddIcon />
        </IconButton>
      )}
      <Typography>{title}</Typography>
      <TextField onChange={event => handleFilterData(event.target.value)} />
    </Container>
  );
};

export default Header;

Header.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  filterBy: PropTypes.string.isRequired,
  addFunction: PropTypes.func,
  title: PropTypes.string.isRequired,

  setFilteredData: PropTypes.func.isRequired,
};

Header.defaultProps = {
  data: [],
  addFunction: null,
};
