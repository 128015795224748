import pluralize from 'pluralize';

export const ToPlural = value => {
  const pluralValue = pluralize(value);

  return pluralValue;
};

export const ToCapitalize = (value, join) => {
  const splitedValue = value.split('_');

  const FirstUpper = val => `${val[0].toUpperCase()}${val.slice(1)}`;

  const capitalizedValue = splitedValue
    .map(spltVal => FirstUpper(spltVal))
    .join(join || '');

  return capitalizedValue;
};

export const RemoveSpaces = value => {
  let str = value || '';

  str = str.replace(/ + /g, ' ');
  str = str.trim();

  return str;
};

export const RemoveSpecials = value => {
  let str = value || '';

  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|\.|,|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ''
  );

  return str;
};

export const ToAlphanumeric = value => {
  let str = value || '';

  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');

  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');

  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');

  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');

  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');

  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');

  str = str.replace(/đ/g, 'd');
  str = str.replace(/Đ/g, 'D');

  str = str.replace(/ç/g, 'c');
  str = str.replace(/Ç/g, 'C');

  str = RemoveSpaces(str);
  str = RemoveSpecials(str);

  return str;
};

export const ToPreventSqlInjection = (value, origin = 'app') => {
  let str = value || '';

  str =
    origin === 'app'
      ? str.replace(/''/g, "'").replace(/'/g, "''")
      : str.replace(/''/g, "'");

  return str;
};
