import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useSession } from '~/modules/sessions/hooks/useSession';
import { useBase } from '~/shared/modules/hooks/useBase';
import { useAccessGroup } from '../../hooks/useAccessGroup';

import WarningDialog from '~/shared/components/Dialogs/WarningDialog';
import TableList, {
  WrapperContainer,
} from '~/shared/components/TableList/TableList.jsx';
import { parseToView } from '~/modules/accessGroups/sanitizers/accessGroup';

import { Container } from './styles';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'contract_key', label: 'Chave do Contrato' },
  { id: 'name', label: 'Nome' },
  { id: 'description', label: 'Descrição' },
];

function List() {
  const history = useHistory();

  const { register, listLoading } = useBase();
  const { show, destroy, newAccessGroup, index, accessGroups } =
    useAccessGroup();
  const { current_accesses, application_key, contract } = useSession();

  const [loaded, setLoaded] = useState(false);
  const [showWarningDialog, setShowWarnigDialog] = useState(false);
  const [accessGroupData, setAccessGroupData] = useState({});

  const loadAccessGroups = useCallback(async () => {
    await index({ search: '', order_by: '', order: '' });
  }, [index]);

  const newItem = useMemo(() => {
    if (
      current_accesses.roles?.[`${application_key}.admin`] ||
      current_accesses.roles?.[`${application_key}.manager`] ||
      current_accesses.permissions?.[`${application_key}.access_groups.post`]
    ) {
      return {
        label: 'ADICIONAR',
        handleFunction: async () => {
          await newAccessGroup({
            dataObj: register?.contract,
            parser: parseToView,
          });
          history.push('/settings/access-groups/form', {
            newAccessGroup: true,
          });
        },
      };
    }

    return null;
  }, [
    history,
    register.contract,
    newAccessGroup,
    application_key,
    current_accesses,
  ]);

  const handleRemove = useCallback(async () => {
    await destroy({
      dataObj: { ...accessGroupData, req_id: '1' },
    });
    setShowWarnigDialog(false);
  }, [destroy, accessGroupData]);

  const actions = useMemo(() => {
    const auxActions = [];

    if (
      current_accesses.roles?.[`${application_key}.admin`] ||
      current_accesses.roles?.[`${application_key}.manager`] ||
      current_accesses.permissions?.[`${application_key}.access_groups.get`]
    ) {
      auxActions.push({
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async auxAccessGroup => {
          await show({
            dataObj: auxAccessGroup,
            parser: parseToView,
          });

          history.push('/settings/access-groups/form', { showUser: true });
        },
      });
    }

    if (
      current_accesses.roles?.[`${application_key}.admin`] ||
      current_accesses.roles?.[`${application_key}.manager`] ||
      current_accesses.permissions?.[`${application_key}.access_groups.put`]
    ) {
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async auxAccessGroup => {
          await show({
            dataObj: auxAccessGroup,
            parser: parseToView,
          });

          history.push('/settings/access-groups/form', { updateUser: true });
        },
      });
    }

    if (
      current_accesses.roles?.[`${application_key}.admin`] ||
      current_accesses.roles?.[`${application_key}.manager`] ||
      current_accesses.permissions?.[`${application_key}.access_groups.delete`]
    ) {
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: async auxAccessGroup => {
          setAccessGroupData(auxAccessGroup);
          setShowWarnigDialog(true);
        },
      });
    }

    return auxActions;
  }, [history, show, application_key, current_accesses]);

  useEffect(() => {
    if (
      current_accesses.roles?.[`${application_key}.admin`] ||
      current_accesses.roles?.[`${application_key}.manager`] ||
      current_accesses.permissions?.[`${application_key}.access_groups.get`]
    ) {
      if (!loaded && !accessGroups.length) loadAccessGroups();

      setLoaded(true);
    }
  }, [
    loadAccessGroups,
    loaded,
    accessGroups,
    current_accesses,
    application_key,
    contract,
  ]);

  return (
    <Container>
      <WrapperContainer>
        <WarningDialog
          showDialog={showWarningDialog}
          setShowDialog={setShowWarnigDialog}
          description="Tem certeza que deseja remover o Grupo de Acesso?"
          onSuccess={handleRemove}
          loading={listLoading}
        />
        <TableList
          title=""
          newItem={newItem}
          headCells={headCells}
          data={accessGroups}
          loadData={loadAccessGroups}
          loading={listLoading}
          actions={actions}
        />
      </WrapperContainer>
    </Container>
  );
}

export default List;
