import apiApp from '~/config/apiApp';
import errorHandler from '~/shared/errors/handler';
import {
  sessionParse,
  formDataParseRecover,
  formDataParserUpdatePassword,
} from './parse';

import { sendPostMessage } from '~/shared/providers/utils/iFrameUtils';

class Session {
  constructor() {
    this.apiApp = apiApp;
  }

  async store({ email = '', password = '', branch_key = '', force = false }) {
    try {
      const response = await this.apiApp.post(
        '/sessions',
        {
          email,
          password,
          branch_key,
        },
        {
          headers: { force },
        }
      );

      if (response.status !== 200) {
        const { error } = response.data;

        throw error;
      }

      const { data } = response;

      if (data.token) {
        apiApp.defaults.headers.authorization = `Bearer ${data.token.token}`;
        apiApp.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;
      }

      const sessionParsed = sessionParse(data.accesses, branch_key);

      if (branch_key) {
        delete sessionParsed.companies;
        delete sessionParsed.branches;
      }

      return { ...data, ...sessionParsed };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Error tentar ao entrar',
        local: 'SessionsRepositoriesAppSessionStore',
      });

      throw errors;
    }
  }

  async changeBranch({ branch_key = '', force = false }) {
    try {
      const { data } = await this.apiApp.post(
        '/change-branch',
        {
          branch_key,
        },
        {
          headers: { force },
        }
      );

      apiApp.defaults.headers.authorization = `Bearer ${data.token.token}`;
      apiApp.defaults.headers[
        'Refresh-Token'
      ] = `Bearer ${data.token.refreshToken}`;

      const sessionParsed = sessionParse(data.accesses, branch_key);
      delete sessionParsed.companies;
      delete sessionParsed.branches;

      return { ...data, ...sessionParsed };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Error tentar ao mudar Empresa',
        local: 'SessionsRepositoriesAppSessionChangeBranch',
      });

      throw errors;
    }
  }

  async refreshToken() {
    try {
      const { data } = await this.apiApp.put('/sessions');

      const appOrigin = localStorage.getItem('@appOrigin');

      apiApp.defaults.headers.authorization = `Bearer ${data.token.token}`;
      apiApp.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;

      if (appOrigin)
        sendPostMessage({ message: 'refreshToken', token: data?.token })(
          appOrigin
        );

      const sessionParsed = sessionParse(
        data.accesses,
        data.logged_branch.branck_key
      );
      delete sessionParsed.companies;
      delete sessionParsed.branches;

      return { ...data, ...sessionParsed };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Erro no refresh token',
        local: 'SessionsRepositoriesAppSessionRefreshToken',
      });

      throw errors;
    }
  }

  async getRecoverPasswordToken({ password_token }) {
    try {
      const { data } = await this.apiApp.get('/passwords', {
        params: {
          password_token: password_token.replace(/\s/g, '+').toString('base64'),
        },
      });
      return { ...data.user };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Erro ao validar token',
        local: 'SessionsRepositoriesRecoverPasswordTokenValidate',
      });
      throw errors;
    }
  }

  async recoverPassword({ password, password_confirmation, password_token }) {
    try {
      const { data } = await this.apiApp.put('/passwords', {
        password,
        password_confirmation,
        password_token: password_token.replace(/\s/g, '+').toString('base64'),
      });
      const updatePasswordParse = formDataParserUpdatePassword({
        password: data.password,
        password_confirmation: data.password_confirmation,
        password_token: data.password_token,
      });

      return { ...data, updatePasswordParse };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Erro ao trocar senha',
        local: 'SessionsRepositoriesRecoverPasswordTokenValidate',
      });
      throw errors;
    }
  }

  async forgotPassword({ email, application_key }) {
    try {
      const { data } = await this.apiApp.post('/passwords', {
        email,
        application_key,
      });
      const recoverPasswordParse = formDataParseRecover({
        email: data.email,
        email_confirmation: data.email,
        application_key: data.application_key,
      });
      return { ...data, recoverPasswordParse };
    } catch (error) {
      const errors = errorHandler({
        error,
        tite: 'Erro ao enviar e-mail de recuperar senha',
        local: 'SessionsRepositoriesAppSessionRecoverPassword',
      });
      throw errors;
    }
  }

  async get({ application_key }) {
    try {
      const response = await this.apiApp.get('/sessions', {
        headers: { 'Application-Keys': application_key },
      });

      if (response.status !== 200) {
        const { error } = response.data;

        throw error;
      }

      const { data } = response;

      if (data.token) {
        apiApp.defaults.headers.authorization = `Bearer ${data.token.token}`;
        apiApp.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;
      }

      const branch_key = data?.logged_branch?.branch_key;

      const sessionParsed = sessionParse(data.accesses, branch_key);

      if (branch_key) {
        delete sessionParsed.companies;
        delete sessionParsed.branches;
      }

      return { ...data, ...sessionParsed };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Error tentar ao entrar',
        local: 'SessionsRepositoriesAppSessionGet',
      });

      throw errors;
    }
  }
}

export default Session;
