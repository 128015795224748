import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useErrorHandler } from '~/shared/errors/hook';

import { useSession } from '~/modules/sessions/hooks/useSession';
import { useUser } from './useUser';

import UserCompanyStoreService from '../services/userCompanyStore';
import UserCompanyDestroyService from '../services/userCompanyDestroy';

import { entityName } from '../constantVariables/userCompany';

const INITIAL_STATE = {
  userCompanyLoading: false,
};

const UserCompanyContext = createContext(INITIAL_STATE);

const userCompanyStoreService = new UserCompanyStoreService();
const userCompanyDestroyService = new UserCompanyDestroyService();
export function UserCompanyProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();
  const { logged_branch } = useSession();
  const { setUserData, users } = useUser();

  const [data, setData] = useState(INITIAL_STATE);

  const setUserCompanyData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const store = useCallback(
    async ({ dataObj = {} }) => {
      setUserCompanyData({ userCompanyLoading: true });
      try {
        const userData = await userCompanyStoreService.execute({
          entityName,
          dataObj,
        });

        const { validations } = userData;

        if (validations?.validation === 'exists') {
          const dataHelper = { [validations.field]: validations.message };

          setUserCompanyData({ userCompanyLoading: false });

          return {
            dataHelper,
            isValidEmail: true,
          };
        }

        if (validations?.validation === 'compoundUnique') {
          const errorMessages = {
            [validations.field]: validations.message,
          };
          setUserCompanyData({ userCompanyLoading: false });

          return { errorMessages };
        }

        history.goBack();

        const { user, ...auxUserCompany } = userData;
        const parsedUser = { ...user, user_companies: [auxUserCompany] };

        setUserData({ users: [parsedUser, ...users] });
        toast.success(
          `Email ${userData.user_key} vinculado a empresa ${
            userData.company_key || ''
          } com sucesso!`
        );
        setUserCompanyData({ userCompanyLoading: false });
        return userData;
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => store({ dataObj }),
        });
        setUserCompanyData({ userCompanyLoading: false });

        throw err;
      }
    },
    [setUserCompanyData, setErrorHandlerData, history, setUserData, users]
  );

  const destroy = useCallback(
    async ({ dataObj = {} }) => {
      try {
        setUserCompanyData({ userCompanyLoading: true });

        const userData = await userCompanyDestroyService.execute({
          entityName,
          dataObj,
          logged_branch,
        });

        toast.success(
          `Email ${userData.user_key} desvinculado da empresa ${
            userData?.user_company?.company_key || ''
          } com sucesso!`
        );

        const removedUser = users.filter(user => user.uuid !== dataObj.uuid);
        setUserData({ users: removedUser });
        setUserCompanyData({ userCompanyLoading: false });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => destroy({ dataObj }),
        });
        setUserCompanyData({ userCompanyLoading: false });

        throw err;
      }
    },
    [setUserCompanyData, setErrorHandlerData, logged_branch, setUserData, users]
  );

  return (
    <UserCompanyContext.Provider value={{ ...data, store, destroy }}>
      {children}
    </UserCompanyContext.Provider>
  );
}

export function useUserCompany() {
  const context = useContext(UserCompanyContext);

  if (!context)
    throw new Error(
      'useUserCompany must be used within an UserCompanyProvider'
    );

  return context;
}

UserCompanyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
