import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string().max(
    100,
    'Este campo deve conter no máximo 100 caracteres'
  ),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Senhas não correspondem'
  ),
});

export default schema;
