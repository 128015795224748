export default function dictionaryEntity({ entityName }) {
  const dictionaryName = {
    access_group: 'Grupo de acesso',
    access_groups: 'Grupos de acessos',
    contract: 'Contrato',
    contracs: 'Contratos',
    user: 'Usuário',
    users: 'Usuários',
  };

  return dictionaryName[entityName];
}
