import styled, { css } from 'styled-components';

export { default as Popper } from '@material-ui/core/Popper';

export const Container = styled.div`
  svg {
    ${props =>
      props.type === 'error' &&
      css`
        color: ${props.theme.colors.red};
        animation: pulse 1.6s infinite;
        border-radius: 50%;
        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(197, 48, 48, 1);
          }
          70% {
            box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
          }
        }
      `}

    ${props =>
      props.type === 'help' &&
      css`
        color: ${props.theme.colors.primary};
      `}
  }
`;

export const Message = styled.p`
  text-align: center;
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  max-width: 15rem;
  color: ${props => props.theme.colors.secondary};

  background: ${props =>
    props.type === 'error'
      ? props.theme.colors.red
      : props.theme.colors.primary};

  &::before {
    content: '';
    max-width: 15rem;
    width: 0;
    height: 0;

    position: absolute;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid
      ${props =>
        props.type === 'error'
          ? props.theme.colors.red
          : props.theme.colors.primary};

    top: 5px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;
