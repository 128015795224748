import { Route, Switch } from 'react-router-dom';
import { SessionsRoute } from '~/modules/sessions/routes';
import ExternalAppAccess from '../pages/externalAppAccess';

const Routes = () => (
  <Switch>
    <ExternalAppAccess path="/login" />
    <Route path="/">
      <SessionsRoute />
    </Route>
  </Switch>
);

export default Routes;
