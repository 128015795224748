import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import THead from './THead';
import TBody from './TBody';

import { Container, TableContainer, TableWrapper } from './styles';

export const TableCheckbox = ({
  data,
  filterBy,
  addFunction,
  title,
  columnInfos,
  actionFunctions,
  onChangeCheckboxAll,
  onChangeCheckbox,
  checkedKey,
  disabled,
}) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => setFilteredData(data), [data]);

  return (
    <Container>
      <Header
        data={data}
        filterBy={filterBy}
        addFunction={addFunction}
        title={title}
        columnInfos={columnInfos}
        setFilteredData={setFilteredData}
      />
      <TableContainer>
        <TableWrapper>
          <THead
            columnInfos={columnInfos}
            actionFunctions={actionFunctions}
            onChangeCheckboxAll={onChangeCheckboxAll}
            disabled={disabled}
          />
          <TBody
            filteredData={filteredData}
            columnInfos={columnInfos}
            actionFunctions={actionFunctions}
            onChangeCheckbox={onChangeCheckbox}
            checkedKey={checkedKey}
            disabled={disabled}
          />
        </TableWrapper>
      </TableContainer>
    </Container>
  );
};

TableCheckbox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  filterBy: PropTypes.string.isRequired,
  addFunction: PropTypes.func,
  title: PropTypes.string.isRequired,
  columnInfos: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  actionFunctions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      handle: PropTypes.func.isRequired,
    })
  ),
  onChangeCheckboxAll: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  checkedKey: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

TableCheckbox.defaultProps = {
  data: [],
  addFunction: null,
  actionFunctions: null,
  onChangeCheckboxAll: () => {},
  onChangeCheckbox: () => {},
  checkedKey: {},
  disabled: false,
};
