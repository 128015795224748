import styled from 'styled-components';
import BoxStyled from '@material-ui/core/Box';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';

export { default as Divider } from '@material-ui/core/Divider';
export { default as Tooltip } from '@material-ui/core/Tooltip';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 2em;
`;
export const TitleBox = styled(BoxStyled).attrs({
  mt: 3,
})``;

export const Typography = styled(TypographyStyled).attrs({
  component: 'h2',
  variant: 'h4',
  color: 'textPrimary',
})`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const TypographyDescription = styled(TypographyStyled).attrs({
  component: 'p',
  variant: 'overline',
  color: 'textSecondary',
})``;

export const SettingsContent = styled(ButtonStyled).attrs({
  color: 'primary',
  size: 'large',
  variant: 'outlined',
})`
  &.MuiButton-root {
    padding: 2em 5em;
    width: 300px;
  }
`;
