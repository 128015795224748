import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ErrorIcon from '@material-ui/icons/Error';

import { useBase } from '~/shared/modules/hooks/useBase';
import { useSession } from '~/modules/sessions/hooks/useSession';
import { useAccessGroup } from '../../hooks/useAccessGroup';

import storeValidatorSchema from '../../validators/accessGroupStore';
import updateValidatorSchema from '../../validators/accessGroupUpdate';

import { parseDataToStoreOrUpdate } from '../../sanitizers/accessGroup';

import { Form, Chip, TextInput } from '~/shared/components/Form';

import { Permissions } from './Permissions';
import { Roles } from './Roles';
import { Branches } from './Branches';
import { Users } from './Users';

import {
  Container,
  TitleBox,
  Typography,
  TypographyDescription,
  Divider,
  Button,
  Box,
  List,
  FieldGrid,
  InputSpacingGrid,
  TabsContainer,
  Tabs,
  Paper,
  Tab,
  SwipeableViews,
  IconError,
} from './styles';

const Formulary = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const { registers, register } = useBase();
  const { store, update, accessGroup, clearState, loading } = useAccessGroup();
  const { contract } = useSession();

  const [canEdit, setCanEdit] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [Tabsvalue, setTabsValue] = useState(0);
  const [errors, setErrors] = useState({});
  const [permission_keys, setPermissionKeys] = useState({});
  const [role_keys, setRoleKeys] = useState({});
  const [branch_keys, setBranchKeys] = useState({});
  const [user_keys, setUserKeys] = useState({});

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const handleSubmit = useCallback(
    async data => {
      try {
        if (accessGroup?.uuid) {
          update({ dataObj: data });
        } else
          await store({
            dataObj: data,
          });
      } catch (err) {
        formRef.current?.setErrors(err.validations);
      }
    },
    [store, accessGroup, update]
  );

  const handleValidationError = data => {
    setErrors({ ...data });
  };

  useEffect(() => {
    if (accessGroup?.uuid && accessGroup?.permission_access_groups?.length) {
      const auxPermissionsSelected = accessGroup?.permission_access_groups;

      const newPermission = {};
      auxPermissionsSelected.forEach(item => {
        newPermission[item.permission_key.replace(/\./g, '$')] = true;
      });

      setPermissionKeys(newPermission);
    }
  }, [accessGroup]);

  useEffect(() => {
    if (accessGroup?.uuid && accessGroup?.role_access_groups?.length) {
      const auxRolesSelected = accessGroup?.role_access_groups;
      const newRole = {};
      auxRolesSelected.forEach(item => {
        newRole[item.role_key.replace(/\./g, '$')] = true;
      });

      setRoleKeys(newRole);
    }
  }, [accessGroup]);

  useEffect(() => {
    if (accessGroup?.uuid && accessGroup?.branch_access_groups?.length) {
      const auxBranchesSelected = accessGroup?.branch_access_groups;
      const newBranch = {};
      auxBranchesSelected.forEach(item => {
        newBranch[item.branch_key.replace(/\./g, '$')] = true;
      });

      setBranchKeys(newBranch);
    }
  }, [accessGroup]);

  useEffect(() => {
    if (accessGroup?.uuid && accessGroup?.user_access_groups?.length) {
      const auxUsersSelected = accessGroup?.user_access_groups;
      const newUser = {};
      auxUsersSelected.forEach(item => {
        newUser[item.user_key.replace(/\./g, '$')] = true;
      });

      setUserKeys(newUser);
    }
  }, [accessGroup]);

  useEffect(() => {
    if (location.state && !location.state.showUser) setCanEdit(true);
  }, [registers, register, location]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        dataParser={parseDataToStoreOrUpdate}
        validatorSchema={
          accessGroup.uuid ? updateValidatorSchema : storeValidatorSchema
        }
        customData={{
          contract_key: contract.contract_key,
          permission_keys,
          user_keys,
          branch_keys,
          role_keys,
        }}
        onValidationError={handleValidationError}
        initialData={accessGroup}
      >
        <TitleBox>
          <Typography>Formulário de Grupos de Acesso</Typography>
          <TypographyDescription>
            Preencha os campos abaixo:
            {accessGroup?.uuid && <Chip name="uuid" label="uuid" disabled />}
          </TypographyDescription>
        </TitleBox>
        <TitleBox>
          <Divider />
        </TitleBox>
        <Box>
          <List>
            <FieldGrid>
              <TypographyDescription>
                Dados do Grupo de Acesso
              </TypographyDescription>
            </FieldGrid>
            <FieldGrid>
              <InputSpacingGrid item xs={3}>
                <TextInput
                  name="name"
                  label="Nome"
                  required
                  disabled={!canEdit}
                />
              </InputSpacingGrid>
              <InputSpacingGrid item xs={3}>
                <TextInput
                  name="description"
                  label="Descrição"
                  required
                  disabled={!canEdit}
                />
              </InputSpacingGrid>
            </FieldGrid>
            <TabsContainer>
              <Paper>
                <Tabs
                  value={Tabsvalue}
                  onChange={handleChangeTabs}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    onClick={() => setPageIndex(0)}
                    label="Permissões"
                    icon={
                      errors?.permission_keys && (
                        <IconError
                          type="error"
                          Icon={ErrorIcon}
                          title={errors?.permission_keys}
                        />
                      )
                    }
                  />
                  <Tab
                    onClick={() => setPageIndex(1)}
                    label="Papeis"
                    icon={
                      errors?.role_keys && (
                        <IconError
                          type="error"
                          Icon={ErrorIcon}
                          title={errors?.role_keys}
                        />
                      )
                    }
                  />
                  <Tab
                    onClick={() => setPageIndex(2)}
                    label="Filiais"
                    icon={
                      errors?.branch_keys && (
                        <IconError
                          type="error"
                          Icon={ErrorIcon}
                          title={errors?.branch_keys}
                        />
                      )
                    }
                  />
                  <Tab
                    onClick={() => setPageIndex(3)}
                    label="Usuários"
                    icon={
                      errors?.user_keys && (
                        <IconError
                          type="error"
                          Icon={ErrorIcon}
                          title={errors?.user_keys}
                        />
                      )
                    }
                  />
                </Tabs>
              </Paper>
              <SwipeableViews index={pageIndex}>
                {pageIndex === 0 ? (
                  <Permissions
                    value={permission_keys}
                    setValue={setPermissionKeys}
                    canEdit={canEdit}
                  />
                ) : (
                  <div />
                )}
                {pageIndex === 1 ? (
                  <Roles
                    value={role_keys}
                    setValue={setRoleKeys}
                    canEdit={canEdit}
                  />
                ) : (
                  <div />
                )}
                {pageIndex === 2 ? (
                  <Branches
                    value={branch_keys}
                    setValue={setBranchKeys}
                    canEdit={canEdit}
                  />
                ) : (
                  <div />
                )}
                {pageIndex === 3 ? (
                  <Users
                    value={user_keys}
                    setValue={setUserKeys}
                    canEdit={canEdit}
                  />
                ) : (
                  <div />
                )}
              </SwipeableViews>
            </TabsContainer>
          </List>
        </Box>
        <FieldGrid>
          <Button
            cancel="cancel"
            type="button"
            onClick={() => history.goBack()}
            color="secondary"
            disabled={loading || !canEdit}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || !canEdit}
          >
            Confirmar
          </Button>
        </FieldGrid>
      </Form>
    </Container>
  );
};

export default Formulary;
