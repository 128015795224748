import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { useSession } from '~/modules/sessions/hooks/useSession';

import backgroundAuth from '~/shared/assets/background-auth.svg';
import Logo from '~/shared/assets/logo-light-codeauth.png';
import SignIn from '../SignIn';
import Branch from '../Branch';
import ForgotPassword from '../ForgotPassword';
import { Container, LoginContainer, LogoContainer } from './styles';

const SignInContent = () => {
  const { sessionPageIndex } = useSession();

  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(sessionPageIndex);
  }, [sessionPageIndex]);

  return (
    <Container background={backgroundAuth}>
      <LogoContainer>
        <img src={Logo} alt="logo" />
      </LogoContainer>
      <LoginContainer>
        <SwipeableViews index={index}>
          <SignIn setIndex={setIndex} />
          <Branch setIndex={setIndex} />
          <ForgotPassword setIndex={setIndex} />
        </SwipeableViews>
      </LoginContainer>
    </Container>
  );
};

export default SignInContent;
