import styled from 'styled-components';

import ContainerStyled from '@material-ui/core/Container';
import CheckboxStyled from '@material-ui/core/Checkbox';
import GridStyled from '@material-ui/core/Grid';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';

export { default as IconButton } from '@material-ui/core/IconButton';
export { default as Divider } from '@material-ui/core/Divider';
export { default as VisibilityIcon } from '@material-ui/icons/Visibility';
export { default as VisibilityOffIcon } from '@material-ui/icons/VisibilityOff';

export const Container = styled(ContainerStyled).attrs({
  component: 'main',
  maxWidth: 'md',
  direction: 'row',
})``;

export const ImageContainer = styled(ContainerStyled)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const CheckboxPassword = styled(CheckboxStyled).attrs({
  color: 'primary',
})``;

export const FieldGrid = styled(GridStyled).attrs(props => ({
  container: true,
  alignItems: 'center',
  justifyContent: 'flex-start',
  direction: props.$column ? 'column' : 'row',
}))``;

export const Typography = styled(TypographyStyled).attrs(props => ({
  component: 'h1',
  variant: props.token ? 'body1' : 'h5',
  color: 'textPrimary',
}))``;

export const Image = styled.img``;
export const LabelImage = styled.label``;
export const InputImage = styled.input``;

export const SaveButton = styled(ButtonStyled).attrs({
  variant: 'contained',
  color: 'primary',
  type: 'submit',
})`
  width: 100%;
`;

export const CancelButton = styled(ButtonStyled).attrs({
  variant: 'contained',
  color: 'secondary',
  type: 'button',
})`
  width: 100%;
`;

export const Avatar = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  background: #fff;
  border-radius: 50%;
  padding: 8px;
  > img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
    object-fit: contain;
  }
  label {
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 30px;
      height: 35px;
      color: #fff;
      text-align: center;
    }
  }
`;
