export function parseToView(acessGroups = []) {
  return acessGroups.map(acessGroup => ({
    ...acessGroup,
  }));
}

export function parseDataToStoreOrUpdate(accessGroup = {}) {
  const user_keys = Object.keys(accessGroup.user_keys || {})
    .filter(key => accessGroup.user_keys[key])
    .map(key => key.replace(/\$/g, '.'));
  const branch_keys = Object.keys(accessGroup.branch_keys || {})
    .filter(key => accessGroup.branch_keys[key])
    .map(key => key.replace(/\$/g, '.'));
  const role_keys = Object.keys(accessGroup.role_keys || {})
    .filter(key => accessGroup.role_keys[key])
    .map(key => key.replace(/\$/g, '.'));
  const permission_keys = Object.keys(accessGroup.permission_keys || {})
    .filter(key => accessGroup.permission_keys[key])
    .map(key => key.replace(/\$/g, '.'));

  return {
    uuid: accessGroup.uuid,
    contract_key: accessGroup.contract_key,
    name: accessGroup.name,
    description: accessGroup.description,
    user_keys,
    branch_keys,
    role_keys,
    permission_keys,
  };
}

export function parseDataToDestroy(accessGroup = {}) {
  return {
    data: [{ uuid: accessGroup.uuid }],
  };
}

export function parseFormData(accessGroup = {}) {
  return {
    uuid: accessGroup.uuid,
    name: accessGroup.name,
    description: accessGroup.description,
  };
}
