import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useBase } from '~/shared/modules/hooks/useBase';
import { useSession } from '~/modules/sessions/hooks/useSession';
import { useUserCompany } from '../../hooks/useUserCompany';

import { ViewUser } from './ViewUser';
import { TextInput, Selector, Loading } from '~/shared/components/Form';

import {
  Container,
  ContentStepper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  FieldGrid,
  InputSpacingGrid,
  StepperButtonContentGrid,
  Button,
} from './styles';

export const RegisterUser = ({
  formRef,
  activeStep,
  setActiveStep,
  isValidEmail,
}) => {
  const { current_accesses, application_key } = useSession();
  const { registers } = useBase();
  const { userCompanyLoading } = useUserCompany();

  const steps = [
    'Dados para vincular usuário a empresa',
    'Cadastro de usuários',
  ];

  const GetStepContent = useCallback(
    step => {
      switch (step) {
        case 0:
          return (
            <FieldGrid>
              <InputSpacingGrid xs={3}>
                <TextInput name="email" label="E-mail" required />
              </InputSpacingGrid>
              {current_accesses.roles[`${application_key}.admin`] && (
                <InputSpacingGrid xs={3}>
                  <Selector
                    name="company_key"
                    label="Empresa"
                    idColumn="company_key"
                    nameColumn="name"
                    options={registers?.companies || []}
                  />
                </InputSpacingGrid>
              )}

              <StepperButtonContentGrid>
                <Button
                  cancel="cancel"
                  disabled={activeStep === 0}
                  onClick={() =>
                    setActiveStep(prevActiveStep => prevActiveStep - 1)
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={userCompanyLoading}
                >
                  {userCompanyLoading ? <Loading /> : 'Vincular'}
                </Button>
              </StepperButtonContentGrid>
            </FieldGrid>
          );
        case 1:
          return <ViewUser formRef={formRef} isValidEmail={isValidEmail} />;
        default:
          return 'Algo deu errado no formulário.';
      }
    },
    [
      activeStep,
      setActiveStep,
      registers?.companies,
      current_accesses,
      application_key,
      formRef,
      isValidEmail,
      userCompanyLoading,
    ]
  );

  return (
    <Container>
      <ContentStepper>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, Index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>{GetStepContent(Index)}</StepContent>
            </Step>
          ))}
        </Stepper>
      </ContentStepper>
    </Container>
  );
};

RegisterUser.propTypes = {
  activeStep: PropTypes.number.isRequired,
  isValidEmail: PropTypes.bool,
  setActiveStep: PropTypes.func.isRequired,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
};

RegisterUser.defaultProps = {
  formRef: null,
  isValidEmail: false,
};
