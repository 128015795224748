import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { getQueryParams } from '~/shared/providers/utils/iFrameUtils';

import { useSession } from '~/modules/sessions/hooks/useSession';

const ExternalAppAcccess = () => {
  const { handleExternalLogin } = useSession();

  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(location.search);

  const storeQueryParams = getQueryParams(urlSearchParams);

  const arrayOfQueryParams = storeQueryParams('param');

  const [
    token,
    type,
    refreshToken,
    contract,
    appOrigin,
    current_accesses,
    application_key,
    loggedBranch,
    expectedRoute,
  ] = arrayOfQueryParams;

  localStorage.setItem('@appOrigin', appOrigin);

  useEffect(() => {
    if (arrayOfQueryParams.length)
      (async () => {
        await handleExternalLogin({
          token,
          refreshToken,
          type,
          contract: JSON.parse(contract),
          current_accesses: JSON.parse(current_accesses),
          application_key,
          logged_branch: JSON.parse(loggedBranch),
        });
        return history.replace(`/${expectedRoute}`);
      })();
  }, [
    arrayOfQueryParams,
    handleExternalLogin,
    loggedBranch,
    contract,
    refreshToken,
    application_key,
    current_accesses,
    history,
    token,
    type,
    expectedRoute,
  ]);

  return <></>;
};

export default ExternalAppAcccess;
