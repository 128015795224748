import * as Yup from 'yup';

const schema = Yup.object().shape({
  image: Yup.string().nullable(),
  email: Yup.string()
    .required('Este campo é obrigatório')
    .email('Formato de e-mail inválido'),
  name: Yup.string().required('Este campo é obrigatório'),
  password: Yup.string().notRequired(),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas devem corresponder.')
    .notRequired(),
});
export default schema;
