import { BrowserRouter } from 'react-router-dom';
import AppTheme from './providers/theme';
import Routes from './routes';
import { GlobalStyle, ToastContainer } from './styles/global';
import { HooksProvider } from './providers/hooks';
import ErrorHandler from './errors/ErrorHandler';

import 'react-toastify/dist/ReactToastify.min.css';

export const App = () => {
  return (
    <BrowserRouter>
      <HooksProvider>
        <AppTheme>
          <Routes />
          <GlobalStyle />
          <ToastContainer />
          <ErrorHandler />
        </AppTheme>
      </HooksProvider>
    </BrowserRouter>
  );
};
