import AppRepository from '../repositories/app/session';

class Session {
  constructor() {
    this.appRepository = new AppRepository();
  }

  async store({ email = '', password = '', branch_key = '', force = false }) {
    try {
      const session = await this.appRepository.store({
        email,
        password,
        branch_key,
        force,
      });

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Error ao fazer Session',
        local: 'SessionsServicesSessionStore',
      };

      throw errors;
    }
  }

  async changeBranch({ branch_key = '', force = false }) {
    try {
      const session = await this.appRepository.changeBranch({
        branch_key,
        force,
      });

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Error ao mudar de Empresa',
        local: 'SessionsServicesSessionChangeBranch',
      };

      throw errors;
    }
  }

  async getRecoverPasswordToken({ password_token }) {
    try {
      const session = await this.appRepository.getRecoverPasswordToken({
        password_token,
      });

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'SessionsServicesRecoverPasswordTokenValidate',
      };

      throw errors;
    }
  }

  async forgotPassword({ email, application_key }) {
    try {
      const session = await this.appRepository.forgotPassword({
        email,
        application_key,
      });

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Erro ao enviar e-mail de recuperar senha',
        local: 'SessionsRepositoriesAppSessionRecoverPassword',
      };

      throw errors;
    }
  }

  async recoverPassword({ password, password_confirmation, password_token }) {
    try {
      const session = await this.appRepository.recoverPassword({
        password,
        password_confirmation,
        password_token,
      });
      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Erro ao trocar senha',
        local: 'SessionsServicesRecoverPasswordTokenValidate',
      };

      throw errors;
    }
  }

  async refreshToken() {
    try {
      const session = await this.appRepository.refreshToken();

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Error tentar entrar novamente',
        local: 'SessionsServicesSessionRefreshToken',
      };

      throw errors;
    }
  }

  async get({ application_key }) {
    try {
      const session = await this.appRepository.get({ application_key });

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Error ao fazer Session',
        local: 'SessionsServicesSessionGet',
      };

      throw errors;
    }
  }
}

export default Session;
