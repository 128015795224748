import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import { Error as ErrorIcon, Help } from '@material-ui/icons';

import { Error } from './styles';

const useStyles = makeStyles({
  root: {
    '& > *': {
      paddingRight: 0,
      maxWidth: 200,
    },
  },
});

function DatePicker({
  name,
  label,
  mask,
  helper,
  defaultValue,
  required,
  ...rest
}) {
  const { fieldName, registerField, error, clearError } = useField(name);

  const classes = useStyles();

  const inputRef = useRef({ value: '' });

  const [value, setValue] = useState(defaultValue || null);

  const handleChange = useCallback(
    date => {
      setValue(date);

      if (inputRef.current)
        inputRef.current.value = date && !isNaN(date) ? date.toISOString() : ''; // eslint-disable-line

      if (error) clearError();
    },
    [error, clearError]
  );

  useEffect(() => {
    inputRef.current.handleChange = handleChange;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, handleChange]);

  return (
    <FormControl>
      <KeyboardDatePicker
        className={classes.root}
        inputVariant="outlined"
        variant="inline"
        format="dd/MM/yyyy"
        label={
          <p>
            {label} {required && <b style={{ color: '#d50000' }}>*</b>}
          </p>
        }
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        autoOk
        size="small"
        error={!!error}
        helperText={' '}
        {...rest}
      />

      {helper && (
        <Error type="help" title={helper}>
          <Help size={20} color="primary" />
        </Error>
      )}

      {error && (
        <Error title={error}>
          <ErrorIcon size={20} color="error" />
        </Error>
      )}
    </FormControl>
  );
}

export default DatePicker;

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helper: PropTypes.string,
  mask: PropTypes.func,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
};

DatePicker.defaultProps = {
  mask: null,
  required: false,
  helper: '',
  defaultValue: '',
};
