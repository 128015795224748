import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, TableRow, TableCell, CheckboxAll } from './styles';

const THead = ({
  columnInfos,
  actionFunctions,
  onChangeCheckboxAll,
  disabled,
}) => {
  const HeadCells = useCallback(
    () => (
      <>
        <TableCell>
          <CheckboxAll onChange={onChangeCheckboxAll} disabled={disabled} />
        </TableCell>
        {columnInfos.map(columnInfo => (
          <TableCell key={columnInfo.key}>{columnInfo.label}</TableCell>
        ))}
      </>
    ),
    [columnInfos, onChangeCheckboxAll, disabled]
  );

  return (
    <Container>
      <TableRow>
        <HeadCells />
        {actionFunctions && <TableCell align="center">Ações</TableCell>}
      </TableRow>
    </Container>
  );
};

export default THead;

THead.propTypes = {
  columnInfos: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  actionFunctions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      handle: PropTypes.func.isRequired,
    })
  ),
  onChangeCheckboxAll: PropTypes.func,
  disabled: PropTypes.bool,
};

THead.defaultProps = {
  actionFunctions: null,
  onChangeCheckboxAll: () => {},
  disabled: false,
};
