import * as Yup from 'yup';

const schema = Yup.object().shape({
  password: Yup.string().required('Este campo é obrigatório'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Senhas não correspondem'
  ),
});

export default schema;
