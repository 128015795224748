import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import NewAccessGroupService from '../services/newAccessGroup';
import ShowService from '~/shared/modules/services/show';
import StoreService from '~/shared/modules/services/store';
import UpdateService from '~/shared/modules/services/update';
import DestroyService from '~/shared/modules/services/destroy';

import { useErrorHandler } from '~/shared/errors/hook';
import { useContract } from '~/modules/contracts/hooks/useContract';
import { useSession } from '~/modules/sessions/hooks/useSession';

import { parseToView as parseToViewContract } from '~/modules/contracts/sanitizers/contract';
import { parseToView } from '~/modules/accessGroups/sanitizers/accessGroup';

import { entityName } from '../constantVariables/acessgroup';

const INITIAL_STATE = {
  accessGroups: [],
  accessGroup: {},
  users: [],
  permissions: [],
  roles: [],
  branches: [],
  listLoading: false,
  loading: false,
};

const AccessGroupContext = createContext(INITIAL_STATE);

const newAccessGroupService = new NewAccessGroupService();
const showService = new ShowService();
const storeService = new StoreService();
const updateService = new UpdateService();
const destroyService = new DestroyService();

export function AccessGroupProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();
  const { contract } = useSession();

  const { show: showContract } = useContract();

  const [data, setData] = useState(INITIAL_STATE);

  const setAccessGroupData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const newAccessGroup = useCallback(
    async ({ dataObj = {}, parser }) => {
      try {
        setAccessGroupData({ accessGroupLoading: true });

        const accessGroupData = await newAccessGroupService.execute({
          entityName,
          dataObj,
          parser,
        });

        setAccessGroupData({ ...accessGroupData, accessGroupLoading: false });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => newAccessGroup({ dataObj, parser }),
        });

        setAccessGroupData({ accessGroupLoading: false });
      }
    },

    [setAccessGroupData, setErrorHandlerData]
  );

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setAccessGroupData({ listLoading: true });
      try {
        const contractData = await showContract({
          dataObj: contract,
          parser: parseToViewContract,
        });

        if (contractData.access_groups)
          setAccessGroupData({
            accessGroups: [...contractData?.access_groups],
          });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => index({ search, order_by, order }),
        });
      }
      setAccessGroupData({ listLoading: false });

      return {};
    },
    [setAccessGroupData, setErrorHandlerData, contract, showContract]
  );

  const store = useCallback(
    async ({ dataObj = {} }) => {
      setAccessGroupData({ loading: true });
      try {
        const accessGroupData = await storeService.execute({
          entityName,
          dataObj,
        });

        setData(oldData => ({
          ...oldData,
          accessGroups: [accessGroupData, ...oldData.accessGroups],
        }));

        history.goBack();

        toast.success('Grupo de acesso cadastrado com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => store({ dataObj }),
        });

        throw err;
      } finally {
        setAccessGroupData({ loading: false });
      }
    },
    [setAccessGroupData, setErrorHandlerData, history]
  );

  const show = useCallback(
    async ({ dataObj = {}, parser }) => {
      setAccessGroupData({ loading: true });
      try {
        const accessGroupData = await showService.execute({
          entityName,
          dataObj,
          parser,
        });

        setAccessGroupData({
          ...accessGroupData,
          accessGroup: accessGroupData.access_group,
        });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => show({ dataObj, parser }),
        });
      }
      setAccessGroupData({ loading: false });
    },
    [setErrorHandlerData, setAccessGroupData]
  );

  const update = useCallback(
    async ({ dataObj = {} }) => {
      setAccessGroupData({ loading: true });
      try {
        const accessGroupData = await updateService.execute({
          entityName,
          dataObj,
          parser: parseToView,
        });

        setData(oldData => ({
          ...oldData,
          accessGroups: [
            accessGroupData,
            ...oldData.accessGroups.filter(
              accessGroup => accessGroup.uuid !== dataObj.uuid
            ),
          ],
        }));

        history.goBack();

        toast.success('Grupo de acesso atualizado com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => update({ dataObj }),
        });

        throw err;
      } finally {
        setAccessGroupData({ loading: false });
      }
    },
    [setAccessGroupData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ dataObj = {} }) => {
      setAccessGroupData({ loading: true });
      try {
        await destroyService.execute({
          entityName,
          dataObj,
        });

        setData(oldData => ({
          ...oldData,
          accessGroups: [
            ...oldData.accessGroups.filter(
              accessGroup => accessGroup.uuid !== dataObj.uuid
            ),
          ],
        }));

        toast.success('Grupo de acesso removido com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => destroy({ dataObj }),
        });

        throw err;
      } finally {
        setAccessGroupData({ loading: false });
      }
    },
    [setAccessGroupData, setErrorHandlerData]
  );

  const clearState = useCallback(async ({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        accessGroup: {},
        listLoading: false,
        loading: false,
      };
    });
  }, []);

  return (
    <AccessGroupContext.Provider
      value={{
        ...data,
        newAccessGroup,
        index,
        store,
        show,
        update,
        destroy,
        clearState,
      }}
    >
      {children}
    </AccessGroupContext.Provider>
  );
}

export function useAccessGroup() {
  const context = useContext(AccessGroupContext);

  if (!context)
    throw new Error(
      'useAccessGroup must be used within an AccessGroupProvider'
    );

  return context;
}

AccessGroupProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
