import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { plural } from 'pluralize';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import ListService from '../services/list';
import ShowService from '../services/show';
import StoreService from '../services/store';
import UpdateService from '../services/update';
import DestroyService from '../services/destroy';
import IndexSelectorService from '../services/indexSelector';

import { useErrorHandler } from '~/shared/errors/hook';

const INITIAL_STATE = {
  registers: {},
  register: {},
  loading: false,
  listLoading: false,
};

const BaseContext = createContext(INITIAL_STATE);

const listService = new ListService();
const storeService = new StoreService();
const showService = new ShowService();
const updateService = new UpdateService();
const destroyService = new DestroyService();
const indexSelectorService = new IndexSelectorService();

export function BaseProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();
  const [data, setData] = useState(INITIAL_STATE);

  const setBaseData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const setRegistersData = useCallback(
    ({ registers = [], entityName = '' }) => {
      setData(oldData => ({
        ...oldData,
        registers: {
          ...oldData.registers,
          [entityName]: [
            ...registers,
            ...(oldData.registers[entityName] || []),
          ],
        },
      }));
    },
    []
  );

  const index = useCallback(
    async ({
      entityName = '',
      search = '',
      order_by = '',
      order = '',
      parser,
    }) => {
      setBaseData({ listLoading: true });
      try {
        const baseData = await listService.execute({
          entityName,
          search,
          order_by,
          order,
          parser,
        });

        setRegistersData({
          entityName: plural(entityName),
          registers: baseData,
        });

        setBaseData({ listLoading: false });
        return baseData;
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => index({ entityName, search, order_by, order }),
        });
      }

      setBaseData({ listLoading: false });

      return {};
    },
    [setBaseData, setErrorHandlerData, setRegistersData]
  );

  const show = useCallback(
    async ({ entityName = '', dataObj = {}, parser }) => {
      setBaseData({ loading: true });
      try {
        const baseData = await showService.execute({
          entityName,
          dataObj,
          parser,
        });

        setData(oldData => ({
          ...oldData,
          register: {
            [entityName]: baseData,
          },
        }));

        setBaseData({ listLoading: false });
        return baseData;
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => show({ entityName, dataObj }),
        });

        return {};
      } finally {
        setBaseData({ loading: false });
      }
    },
    [setBaseData, setErrorHandlerData]
  );

  const store = useCallback(
    async ({ entityName = '', dataObj = {}, parser }) => {
      setBaseData({ loading: true });
      try {
        const baseData = await storeService.execute({
          entityName,
          dataObj,
          parser,
        });

        setRegistersData({
          registers: [baseData],
          entityName: plural(entityName),
        });

        history.goBack();

        toast.success(`${entityName} cadastrado com sucesso!`);
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => store({ entityName, dataObj, parser }),
        });
      }
      setBaseData({ loading: true });
    },
    [setBaseData, setErrorHandlerData, history, setRegistersData]
  );

  const update = useCallback(
    async ({ entityName = '', dataObj = {}, parser }) => {
      setBaseData({ loading: true });
      try {
        const baseData = await updateService.execute({
          entityName,
          dataObj,
          parser,
        });

        const entityNamePlural = plural(entityName);

        setData(oldData => ({
          ...oldData,
          registers: {
            ...oldData.registers,
            [entityNamePlural]: [
              baseData,
              ...oldData.registers[entityNamePlural].filter(
                item => item.uuid !== baseData.uuid
              ),
            ],
          },
        }));

        history.goBack();

        toast.success(`${entityName} atualizado com sucesso!`);
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => update({ entityName, dataObj, parser }),
        });
      }
      setBaseData({ loading: false });
    },
    [setBaseData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ entityName = '', dataObj = {} }) => {
      setBaseData({ listLoading: true });
      try {
        await destroyService.execute({
          entityName,
          dataObj,
        });

        const entityNamePlural = plural(entityName);

        setData(oldData => ({
          ...oldData,
          registers: {
            ...oldData.registers,
            [entityNamePlural]: [
              ...oldData.registers[entityNamePlural].filter(
                item => item.uuid !== dataObj.uuid
              ),
            ],
          },
        }));

        toast.success(`${entityNamePlural} removido com sucesso!`);
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => destroy({ entityName, dataObj }),
        });
      }

      setBaseData({ listLoading: false });
    },
    [setBaseData, setErrorHandlerData]
  );

  const indexSelector = useCallback(
    async ({ entityName = '', search = '', parser }) => {
      setBaseData({ listLoading: true });
      try {
        const baseData = await indexSelectorService.execute({
          entityName,
          search,
          parser,
        });

        return baseData;
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => indexSelector({ entityName, search }),
        });
      }

      setBaseData({ listLoading: false });
      return [];
    },
    [setBaseData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        register: {},
        userLoading: false,
        userListLoading: false,
      };
    });
  }, []);

  return (
    <BaseContext.Provider
      value={{
        ...data,
        setBaseData,
        index,
        show,
        store,
        update,
        destroy,
        clearState,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
}

export function useBase() {
  const context = useContext(BaseContext);

  if (!context) throw new Error('useBase must be used within an BaseProvider');

  return context;
}

BaseProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
