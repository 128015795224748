/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Group } from '@material-ui/icons';
import { useSession } from '../../hooks/useSession';
import validatorSchema from '../../validators/SignIn';
import { formDataParser } from '../../repositories/app/parse';
import {
  LoginItems,
  Avatar,
  Typography,
  Button,
  ForgotPasswordContainer,
  ForgotPasswordContent,
  Link,
} from './styles';

import { Form, Loading, Selector } from '~/shared/components/Form';

function Branch({ setIndex }) {
  const {
    sessionLoading,
    branches,
    companies,
    email,
    password,
    signIn,
    signOut,
  } = useSession();

  const formRef = useRef(null);

  const handleSubmit = async ({ branch_key }) => {
    try {
      await signIn({ email, password, branch_key });
    } catch (err) {
      formRef.current?.setErrors(err.validations);
    }
  };

  return (
    <LoginItems>
      <Avatar>
        <Group />
      </Avatar>
      <Typography>Selecionar Empresa</Typography>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        validatorSchema={validatorSchema}
        dataParser={formDataParser}
      >
        <Selector
          name="company_key"
          label="Selecione alguma Empresa"
          idColumn="company_key"
          nameColumn="name"
          options={companies}
          loading={sessionLoading}
        />
        <Selector
          name="branch_key"
          label="Selecionar Filial"
          idColumn="branch_key"
          nameColumn="first_name"
          options={branches}
          loading={sessionLoading}
        />

        <Button type="submit" disabled={sessionLoading}>
          {sessionLoading && <Loading />}
          Acessar
        </Button>
      </Form>
      <ForgotPasswordContainer>
        <ForgotPasswordContent>
          <Link
            onClick={() => {
              setIndex(0);
              signOut();
            }}
          >
            <p>Voltar para a tela de Login</p>
          </Link>
        </ForgotPasswordContent>
      </ForgotPasswordContainer>
    </LoginItems>
  );
}

export default Branch;

Branch.propTypes = {
  setIndex: PropTypes.func,
};

Branch.defaultProps = {
  setIndex: null,
};
