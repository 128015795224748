import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSession } from '../../hooks/useSession';
import validatorSchema from '../../validators/UpdatePassword';
import { formDataParserUpdatePassword } from '../../repositories/app/parse';

import { LoginItems, Typography, Button } from './styles';

import { Form, TextInput, Loading } from '~/shared/components/Form';

const RecoverPasswordPage = () => {
  const { getRecoverPasswordToken, recoverPassword, sessionLoading } =
    useSession();

  const formRef = useRef(null);
  const location = useLocation();

  const [user, setUser] = useState({});
  const [userLoaded, setUserLoaded] = useState(false);

  const query = new URLSearchParams(location.search);
  const passKey = query.get('password_token');
  const appUrl = query.get('url');

  const handleSubmit = async ({
    password,
    password_confirmation,
    externalApplicationUrl = appUrl,
  }) => {
    try {
      await recoverPassword({
        password,
        password_confirmation,
        password_token: passKey,
      });

      window.parent.postMessage(
        'sendUpdatePasswordSuccess',
        externalApplicationUrl
      );
    } catch (err) {
      if (
        err.code === 'E_VALIDATION_FAILED' &&
        err.validations.password_token
      ) {
        window.parent.postMessage(
          'sendPasswordTokenInvalid',
          externalApplicationUrl
        );
      }
      if (err.code === 'E_VALIDATION_FAILED') {
        formRef.current?.setErrors(err.validations);
      }
    }
  };

  useEffect(() => {
    async function getRecover() {
      const recoverData = await getRecoverPasswordToken({
        password_token: passKey,
        externalApplicationUrl: appUrl,
      });
      setUser(recoverData);
      setUserLoaded(true);
    }

    if (passKey) getRecover();
  }, [appUrl, getRecoverPasswordToken, passKey]);

  useEffect(() => {
    if (userLoaded && user?.uuid) {
      window.parent.postMessage('sendTokenValidateSuccess', appUrl);
    }
  }, [appUrl, user?.uuid, userLoaded]);

  useEffect(() => {
    window.parent.postMessage('codeAuthLoaded', appUrl);
  }, [appUrl]);

  return (
    <LoginItems>
      {!userLoaded || !user?.uuid ? (
        <>
          <Typography>Validando Token</Typography>
          <div>
            <Loading />
          </div>
        </>
      ) : (
        <>
          <Typography>Olá! {user.name}</Typography>
          <Typography>Digite sua nova senha</Typography>
          <Form
            formRef={formRef}
            onSubmit={handleSubmit}
            externalApplicationUrl={appUrl}
            validatorSchema={validatorSchema}
            dataParser={formDataParserUpdatePassword}
          >
            <TextInput name="password" label="Nova senha" type="password" />
            <TextInput
              name="password_confirmation"
              label="Confirmar senha"
              type="password"
            />
            <Button disabled={sessionLoading} type="submit">
              {sessionLoading && <Loading />}
              Alterar
            </Button>
          </Form>
        </>
      )}
    </LoginItems>
  );
};

export default RecoverPasswordPage;
