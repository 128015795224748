import * as Yup from 'yup';

const yupValidator = async (
  data = {},
  dataParser = null,
  validatorSchema = {}
) => {
  try {
    const parsedData = dataParser ? dataParser(data) : data;

    await validatorSchema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
};

export default yupValidator;
