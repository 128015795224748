export function parseDataToOrUpdatePassword(user = {}) {
  return {
    uuid: user.uuid,
    name: user.name,
    email: user.email,
    is_admin: user.is_admin,
    image: user.image,
    password: user.password,
    password_confirmation: user.password_confirmation,
  };
}
