import styled from 'styled-components';

import ContainerStyled from '@material-ui/core/Container';
import PaperStyled from '@material-ui/core/Paper';
import GridStyled from '@material-ui/core/Grid';

export const Container = styled(ContainerStyled).attrs({
  component: 'main',
  maxWidth: 'xl',
  direction: 'column',
})`
  margin-top: 4em;
  img {
    width: 120px;
    padding: 1em;
    object-fit: contain;
  }
  height: calc(100vh - 200px);
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left;
`;

export const LoginContainer = styled(PaperStyled).attrs({
  variant: 'elevation',
})`
  padding: 1em;
  &.MuiPaper-root {
    max-width: 400px;
    margin: 0 auto;
  }
`;
export const LogoContainer = styled(GridStyled).attrs({
  container: true,
  item: true,
  justify: 'center',
})`
  img {
    width: 250px;
  }
`;
