import { useHistory } from 'react-router-dom';
import { useCallback, useRef, useState, useEffect } from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { useSession } from '../../hooks/useSession';
import { useProfile } from '../../hooks/useProfile';
import validatorSchema from '../../validators/UpdateProfile';
import { parseDataToOrUpdatePassword } from '../../sanitizers/profile';
import convertImage from '~/shared/Utils/convertImage';
import { toastWarning } from '~/shared/Utils/toast';
import imageProfile from '~/shared/assets/noImage.png';
import { Form, TextInput, Loading } from '~/shared/components/Form';
import { setData } from '../../repositories/localStorage/session';

import {
  Container,
  SaveButton,
  CancelButton,
  FieldGrid,
  CheckboxPassword,
  Avatar,
  Image,
  LabelImage,
  InputImage,
  Divider,
  VisibilityIcon,
  VisibilityOffIcon,
  IconButton,
} from './styles';

const Profile = () => {
  const formRef = useRef(null);
  const [isPassword, setIsPassword] = useState(false);

  const history = useHistory();

  const { user } = useSession();
  const { loading, updateUser } = useProfile();

  const [userName, setUserName] = useState('');
  const [seePassword, setSeePassword] = useState(false);
  const [seePasswordConfirmation, setSeePasswordConfirmation] = useState(false);

  const [image, setImage] = useState('');

  function handleTogglePassword() {
    setSeePassword(!seePassword);
  }

  function handleTogglePasswordConfirmation() {
    setSeePasswordConfirmation(!seePasswordConfirmation);
  }

  const handlePassword = useCallback(
    event => {
      const { checked } = event.target;

      if (!checked) {
        formRef.current.setData({
          password: '',
          password_confirmation: '',
        });
      }

      setIsPassword(checked);
    },
    [formRef, setIsPassword]
  );

  const handleSubmit = async dataObj => {
    try {
      dataObj.image = image || user.image;
      dataObj.uuid = user.uuid;
      if (!isPassword) {
        delete dataObj.password;
        delete dataObj.password_confirmation;
      }

      await updateUser({ dataObj });
      setData({ user: dataObj });
    } catch (err) {
      if (err.code === 'E_VALIDATION_FAILED') {
        formRef.current?.setErrors(err.validations);
      }
    }
  };

  useEffect(() => {
    setUserName(user?.name);
  }, [user]);

  async function handleAvatarChange(e) {
    const base64Data = await convertImage(e.target.files[0]).catch(err =>
      alert(JSON.stringify(err && err.message))
    );

    if (base64Data) {
      const validatorImage = base64Data.startsWith('data:image');
      if (!validatorImage) {
        toastWarning('O formato do arquivo é inválido. Selecione uma imagem.');
      }
    }

    setImage(base64Data);
  }

  function handleCancel() {
    history.goBack();
  }

  return (
    <Container>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        validatorSchema={validatorSchema}
        dataParser={parseDataToOrUpdatePassword}
      >
        <Avatar>
          <Image src={image || user.image || imageProfile} alt="profile" />
          <LabelImage htmlFor="avatar">
            <AddAPhotoIcon />
            <InputImage
              type="file"
              id="avatar"
              accept="image/png, image/jpeg"
              // eslint-disable-next-line react/jsx-no-bind
              onChange={handleAvatarChange}
            />
          </LabelImage>
        </Avatar>

        <FieldGrid
          container
          spacing={5}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FieldGrid item xs={4}>
            <TextInput
              name="name"
              label="Nome"
              value={userName}
              onChange={e => setUserName(e.target.value)}
            />
          </FieldGrid>
          <FieldGrid item xs={4}>
            <TextInput
              name="email"
              label="Email"
              disabled
              value={user?.email}
              type={seePassword ? 'text' : 'email'}
            />
          </FieldGrid>
        </FieldGrid>
        <Divider />

        <FieldGrid
          container
          spacing={5}
          style={{
            display: 'flex',
            justifyContent: 'left',
            marginTop: '5px',
          }}
        >
          <FieldGrid item xs={2}>
            <p
              style={{
                fontSize: '12px',
              }}
            >
              Alterar Senha
            </p>
            <CheckboxPassword
              color="primary"
              checked={isPassword}
              onChange={handlePassword}
            />
          </FieldGrid>
          <FieldGrid item xs={4}>
            <TextInput
              name="password"
              label="Nova Senha"
              disabled={!isPassword}
              type={seePassword ? 'text' : 'password'}
              endAdornment={
                // eslint-disable-next-line react/jsx-no-bind
                <IconButton onClick={handleTogglePassword}>
                  {seePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              }
            />
          </FieldGrid>
          <FieldGrid item xs={4}>
            <TextInput
              name="password_confirmation"
              label="Confirmar Nova Senha"
              disabled={!isPassword}
              type={seePasswordConfirmation ? 'text' : 'password'}
              endAdornment={
                // eslint-disable-next-line react/jsx-no-bind
                <IconButton onClick={handleTogglePasswordConfirmation}>
                  {seePasswordConfirmation ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              }
            />
          </FieldGrid>
        </FieldGrid>

        <FieldGrid
          container
          spacing={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <FieldGrid item xs={3}>
            <SaveButton disabled={loading}>
              {loading && <Loading />}
              Salvar
            </SaveButton>
          </FieldGrid>
          <FieldGrid item xs={3}>
            <CancelButton disabled={loading} onClick={() => handleCancel()}>
              {loading && <Loading />}
              Cancelar
            </CancelButton>
          </FieldGrid>
        </FieldGrid>
      </Form>
    </Container>
  );
};

export default Profile;
