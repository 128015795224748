import React from 'react';
import PropTypes from 'prop-types';

import { ErrorHandlerProvider } from '../../errors/hook';
import { BaseProvider } from '~/shared/modules/hooks/useBase';
import { SessionProvider } from '~/modules/sessions/hooks/useSession';
import { ThemeProvider } from '~/shared/providers/hooks/theme/useTheme';
import { UserProvider } from '~/modules/users/hooks/useUser';
import { UserCompanyProvider } from '~/modules/users/hooks/useUserCompany';
import { AccessGroupProvider } from '~/modules/accessGroups/hooks/useAccessGroup';
import { ProfileProvider } from '~/modules/sessions/hooks/useProfile';

export function HooksProvider({ children }) {
  return (
    <ErrorHandlerProvider>
      <ThemeProvider>
        <SessionProvider>
          <ProfileProvider>
            <BaseProvider>
              <UserProvider>
                <UserCompanyProvider>
                  <AccessGroupProvider>{children}</AccessGroupProvider>
                </UserCompanyProvider>
              </UserProvider>
            </BaseProvider>
          </ProfileProvider>
        </SessionProvider>
      </ThemeProvider>
    </ErrorHandlerProvider>
  );
}

HooksProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
