import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAccessGroup } from '../../hooks/useAccessGroup';

import { WrapperContainer } from '~/shared/components/TableList/TableList.jsx';
import { TableCheckbox } from '~/shared/components/TableCheckbox';

const headCells = [
  { key: 'role', label: 'Papel' },
  { key: 'name', label: 'Nome' },
];

export const Roles = ({ value, setValue, canEdit }) => {
  const { roles } = useAccessGroup();

  const accessGroupRolesList = useMemo(() => {
    const axuAccessGroupRolesList = roles?.map(role => ({
      ...role,
      key: role.role_key.replace(/\./g, '$'),
    }));

    return axuAccessGroupRolesList;
  }, [roles]);

  const handleSelectAll = useCallback(
    event => {
      const { checked } = event?.target;

      const newBranch = {};
      accessGroupRolesList.forEach(branch => {
        newBranch[branch.key] = checked;
      });

      setValue(newBranch);
    },
    [setValue, accessGroupRolesList]
  );

  const handleChange = useCallback(
    (event, key) => {
      const { checked } = event?.target;

      setValue({
        ...value,
        [key]: checked,
      });
    },
    [value, setValue]
  );

  return (
    <WrapperContainer style={{ width: '100%' }}>
      <TableCheckbox
        title=""
        data={accessGroupRolesList}
        filterBy="name"
        columnInfos={headCells}
        onChangeCheckboxAll={handleSelectAll}
        onChangeCheckbox={handleChange}
        checkedKey={value}
        disabled={!canEdit}
      />
    </WrapperContainer>
  );
};

Roles.propTypes = {
  value: PropTypes.shape({}),
  setValue: PropTypes.func,
  canEdit: PropTypes.bool,
};

Roles.defaultProps = {
  value: {},
  setValue: () => {},
  canEdit: false,
};
