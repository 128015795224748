import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import AppRepository from '../repositories/app/store';

class StoreBaseService {
  constructor(props = { withEntityName: true }) {
    this.appRepository = new AppRepository(props);
  }

  async execute({ entityName = '', dataObj = {}, parser }) {
    try {
      const baseData = await this.appRepository.store({
        entityName,
        dataObj,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao criar ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: `StoreBaseServiceExecute`,
      };

      throw errors;
    }
  }
}

export default StoreBaseService;
